import React from "react";
import {Typography, IconButton, CircularProgress} from '@mui/material';
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';

const Player = ({
  item,
  currentPlayingId,
  playVoiceNote,
  hasStartedPlaying,
  pauseVoiceNote,
  resumeVoiceNote,
  isVoiceNotePaused,
  setIsVoiceNotePaused
}) => {
  
  const handlePauseVoiceNote = async () => {
    try {
      await pauseVoiceNote();
      setIsVoiceNotePaused(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {currentPlayingId !== item?.node?.id && (
        <IconButton
          style={currentPlayingId === item?.node?.id ? styles.isNotPlaying : styles.isPlaying}
          onClick={() => playVoiceNote(item?.node?.fileUrl, item?.node?.id)}
        >
          <PlayCircleOutline fontSize="large" style={{ color: "#1D8A50" }} />
          <Typography style={{ color: "#1D8A50" }}>Voice Note</Typography>
        </IconButton>
      )}
      {currentPlayingId === item?.node?.id && (
        <>
          {hasStartedPlaying ? (
            <IconButton
              style={styles.isPlaying}
              onClick={handlePauseVoiceNote}
            >
              <PauseCircleOutline fontSize="large" style={{ color: "#1D8A50" }} />
              <Typography style={{ color: "#1D8A50" }}>Pause Voice Note</Typography>
            </IconButton>
          ) : isVoiceNotePaused ? (
            <IconButton style={styles.isPlaying} onClick={() => resumeVoiceNote()}>
              <PlayCircleOutline fontSize="large" style={{ color: "#1D8A50" }} />
              <Typography style={{ color: "#1D8A50" }}>Resume Voice Note</Typography>
            </IconButton>
          ) : (
            <CircularProgress style={{ color: "#1D8A50" }} size={25} />
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  isPlaying: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  isNotPlaying: {
    borderColor: "#1D8A50",
  },
};

export default Player;
