import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import { boxSizing, display, styled, width } from "@mui/system";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";

import AnnouncementCard from "../../components/cards/AnnouncementCard";
import ResourceCard from "../../components/cards/ResourceCard";
import PageLoader from "../../components/loaders/PageLoader";
import { updateHomeData } from "../../redux/actions/dataAction";
import vcPicture from "../../assets/images/vc.jpg";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        P
    </div>
    );
  }

export default function DashboardPage() {
    const [loading, setLoading] = useState(true);
    const homeData = useSelector((store) => store.data.homeData);
    const dispatch = useDispatch();
    const [showAll, setShowAll] = useState(false);
    
    const [data, setData] = useState({});
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
    
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
    
        fetch(`${window.backendUrl}/api/v1/super-admin/welcome-note`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setData(result.data);
          })
          .catch((error) => console.error(error));
      }, []);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      };

      const getPageData = () => {
        let config = {
            method: 'get',
            url: `${window.backendUrl}/api/v1/home`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("userToken")}`
            }
        };

        axios.request(config)
        .then((response) => {
            let hData = response?.data?.data;
            const radioIndex = hData?.resourceData?.findIndex(item => item.isRadio === true);
            const [radioItem] = hData?.resourceData?.splice(radioIndex, 1);
            hData?.resourceData?.splice(3, 0, radioItem);
            dispatch(updateHomeData(hData));
            //setData(response?.data?.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error)
            
            setLoading(false);
        });
    }

    useEffect(() => {
        getPageData();
    }, []);

    return (
        <PageWrapper>
            <h2 className="page-title">Dashboard</h2>
            {loading && Object.keys(homeData).length === 0 ? 
                <PageLoader open={loading} /> 
                : 
                <>
                    {data?.status &&
                        <>
                            <div className="vc-welcome-div">
                                <div className="col-50">
                                    {data?.imageUrl && <img src={`${window.backendUrl}/${data?.imageUrl}`} alt="VC" width="100%" />}
                                </div>
                                <div className="col-50">
                                    <h2>{data?.title}</h2>
                                    {
                                        data?.paragraphs?.slice(0, showAll ? data?.paragraph?.length : 3).map((item, index) => (
                                        <p key={index} style={{lineHeight: '26px', textAlign: 'justify', textJustify: 'inter-word'}}>{item}</p>
                                        ))
                                    }
                                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <div className="col-50"></div>
                                        <div className="col-50" style={{display: 'flex', flexDirection: 'row', justifyContent: 'right', alignContent: 'right'}}><button style={{backgroundColor: '#1D8A50', color: '#fff'}} onClick={() => setShowAll(!showAll)}>{showAll ? "Show Less" : "Show More"}</button></div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="annoucement-container">
                        <Slider {...settings}>
                            {homeData?.slideData?.map((item, index) => (
                                <AnnouncementCard data={item} toLink="#" />
                            ))}
                        </Slider>
                    </div>
                    <h3>Portal Sections</h3>
                    <div class="item-container">
                        {homeData?.resourceData?.map((item, index) => (
                            <div class="item" key={index}>
                                <ResourceCard 
                                    _id={item._id} 
                                    name={item.resource_name} 
                                    url={item.resource_url} 
                                    iconUrl={item.resource_icon} 
                                    status={item.status}
                                    isRadio={item?.isRadio}
                                />
                            </div>
                        ))}
                    </div>
                </>
            }
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',
}));
