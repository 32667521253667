import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';

import AnnouncementCard from "../../components/cards/AnnouncementCard";
import { useDispatch, useSelector } from "react-redux";
import { changeAnnouncementCount } from "../../redux/actions/countAction";

export default function AnnouncementDetailPage() {
    const viewedAnnouncement = useSelector((store) => store.count.viewAnnouncement);
    const announcementCount = useSelector((store) => store.count.announcementCount);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!viewedAnnouncement?.is_read){
            let data = JSON.stringify({
                "announcementId": viewedAnnouncement?._id
            });
            let config = {
                method: 'post',
                url: `${window.backendUrl}/api/v1/announcements/read`,
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("userToken")}`
                },
                data: data
            };

            axios.request(config)
            .then((response) => {
                //console.log('AGM => ', response.data)
                dispatch(changeAnnouncementCount(announcementCount - 1));
            })
            .catch((error) => {
                //console.log(error);
            });
        }
    }, [viewedAnnouncement]);

    return (
        <PageWrapper>
            <h2 className="page-title">Annoucement Detail</h2> 
            <AnnouncementCard data={viewedAnnouncement} isBig={true} />
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',
    
    '& .title': {
        fontWeight: 500,
        fontSize: 18,
        color: '#333333',
        paddingHorizontal: 16,
    },
    '& .card': {
        backgroundColor: "#ecf5f4",
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        minHeight: 20,
        padding: 20,
        borderRadius: 8,
        marginBottom: 4,
        border: '1px solid #ecf5f4',
    },
    '& .cardIcon': {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        backgroundColor: '#1D8A50',
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .cardText': {
        width: '100%',
    },
    '& .cardDots': {
        width: 30,
    },
    '& .card-title': {
        color: 'darkgreen',
        fontSize: 16,
        fontWeight: '600',
        padding: 0,
        margin: 0
    },
    '& .content': {
        color: 'darkgreen',
        fontSize: 13,
        fontWeight: 500,
        padding: 0,
        margin: 0
    },
    '& .timestamp': {
        color: 'darkgreen',
        fontSize: 14,
        marginBottom: 0,
    },
}));
