import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import { FACULTIES, DEPARTMENTS, STUDY_CENTERS } from "../../utils/constants";
import PageLoader from "../../components/loaders/PageLoader";
import { useSelector, useDispatch } from "react-redux";
import { updateViewAnnouncement } from "../../redux/actions/countAction";
import { updateAnnouncementsData } from "../../redux/actions/dataAction";

import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "../../components/button/Button";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function AnnouncementsPage() {
  const [loading, setLoading] = useState(true);
  const profile = useSelector((store) => store.user.profile);
  const [category, setCategory] = useState("All");
  const [categorySpecific, setCategorySpecific] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buttonTextCreate, setButtonTextCreate] = useState("Submit");
  const [buttonLoadingCreate, setButtonLoadingCreate] = useState(false);

  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [open, setOpen] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [switchValue, setSwitchValue] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentItemToggle, setCurrentItemToggle] = useState(null);
  const announcementsData = useSelector(
    (store) => store.data.announcementsData
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newAnnouncements = announcementsData.filter(
    (announcement) => !announcement.is_read
  );
  const oldAnnouncements = announcementsData.filter(
    (announcement) => announcement.is_read
  );

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    if (currentItem) {
      setEditTitle(currentItem?.title);
      setEditDescription(currentItem?.description);
    }
  }, [currentItem]);

  const getPageData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${window.backendUrl}/api/v1/announcements`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(updateAnnouncementsData(response.data.data));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleClick = (item) => {
    dispatch(updateViewAnnouncement(item));
    navigate(`/announcement/${item._id}`);
  };

  const handleOpen = (item) => {
    setCurrentItem(item);
    setButtonText("Save");
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentItem(null);
    setButtonText("Save");
    setOpen(false);
  };

  const handleCreate = () => {
    if (!category || !title || !description) {
      Swal.fire({
        title: "Error!",
        text: "Title and description are required!",
        icon: "error",
        confirmButtonText: "Close",
      });
    } else {
      setButtonLoadingCreate(true);
      let data = JSON.stringify({
        title: title,
        category: category,
        description: description,
      });

      let config = {
        method: "post",
        url: `${window.backendUrl}/api/v1/announcements/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response?.data);
          dispatch(updateAnnouncementsData(response?.data?.data));
          setButtonTextCreate("Announcement created successfully!");
          setButtonLoadingCreate(false);
          setCategory("All");
          setTitle("");
          setDescription("");
        })
        .catch((error) => {
          console.log(error);
          setButtonTextCreate("Error, try again!");
          setButtonLoadingCreate(false);
        });
    }
  };

  const handleSave = () => {
    setLoading(true);
    let data = JSON.stringify({
      announcementId: currentItem?._id,
      title: editTitle,
      description: editDescription,
    });

    let config = {
      method: "patch",
      url: `${window.backendUrl}/api/v1/announcements/edit`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
        dispatch(updateAnnouncementsData(response?.data?.data));
        setButtonText("Saved successfully!");
        setButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setButtonText("Error, try again!");
        setButtonLoading(false);
      });
  };

  const handleToggle = (checked, item) => {
    //console.log(checked, item)
    setSwitchValue(checked);
    setCurrentItemToggle(item);
    let data = JSON.stringify({
      announcementId: item?._id,
      status: checked,
    });

    let config = {
      method: "patch",
      url: `${window.backendUrl}/api/v1/announcements/edit-status`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
        dispatch(updateAnnouncementsData(response?.data?.data));
        setSwitchValue(null);
        setCurrentItemToggle(null);
      })
      .catch((error) => {
        console.log(error);
        setSwitchValue(null);
        setCurrentItemToggle(null);
      });
  };

  const handleDelete = (item) => {
    Swal.fire({
      //title: 'Are you sure you want to logout?',
      title: "Delete",
      text: "Are you sure you want to delete the announcement?",
      //icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let data = JSON.stringify({
          announcementId: item?._id,
          status: 2,
        });

        let config = {
          method: "patch",
          url: `${window.backendUrl}/api/v1/announcements/edit-status`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log(response?.data);
            dispatch(updateAnnouncementsData(response?.data?.data));
            setSwitchValue(null);
            setCurrentItemToggle(null);
          })
          .catch((error) => {
            console.log(error);
            setSwitchValue(null);
            setCurrentItemToggle(null);
          });
      }
    });
  };

  const RenderCardItem = ({ item, color }) => (
    <>
      <div
        onClick={() => handleClick(item)}
        className="card"
        style={{ backgroundColor: color, cursor: "pointer" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            minHeight: 20,
          }}
        >
          <div className="cardLayout">
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <div className="cardIcon">
                <CampaignIcon style={{ color: "#fff", fontSize: "30px" }} />
              </div>
              <p className="card-title">{item?.title}</p>
            </div>
            <div>
              <p
                className="timestamp"
                style={{ paddingTop: 5, textAlign: "right" }}
              >
                {item?.date?.substr(0, 10)} {item?.date?.substr(11, 5)}
              </p>
            </div>
          </div>
          <div className="cardText">
            {item?.description?.length > 200 ? (
              <p className="content">{item?.description.substr(0, 200)}...</p>
            ) : (
              <p className="content">{item?.description}</p>
            )}
          </div>
        </div>
      </div>
      {(profile?.user_type === "admin" ||
        profile?.user_type === "superadmin") && (
        <div
          style={{
            border: "0px solid red",
            height: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <Switch
            {...label}
            style={{ color: "#1D8A50" }}
            //value={switchValue}
            checked={
              currentItemToggle?._id === item?._id ? switchValue : item?.status
            }
            onChange={(e) => handleToggle(e.target.checked, item)}
          />
          <button
            onClick={() => handleDelete(item)}
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#D12B1E",
              backgroundColor: "transparent",
              border: "0px solid #1D8A50",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "12px",
              marginTop: "10px",
              paddingBottom: "10px",
              height: "auto",
              boxSizing: "border-box",
            }}
          >
            <DeleteIcon style={{ fontSize: "14px" }} /> Delete
          </button>
          <button
            onClick={() => handleOpen(item)}
            style={{
              display: "flex",
              flexDirection: "row",
              color: "#1D8A50",
              backgroundColor: "transparent",
              border: "0px solid #1D8A50",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "12px",
              marginTop: "10px",
              paddingBottom: "10px",
              height: "auto",
              boxSizing: "border-box",
            }}
          >
            <EditIcon style={{ color: "#1D8A50", fontSize: "14px" }} /> Edit
          </button>
        </div>
      )}
    </>
  );

  return (
    <PageWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: "20px" }}
            >
              Edit Annoucements
            </Typography>
            <div style={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
            </div>
          </div>
          <input
            className="input"
            style={{ border: "1px solid #ccc" }}
            placeholder="Title"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
          />
          <textarea
            className="input"
            style={{
              border: "1px solid #ccc",
              height: "100px",
              borderRadius: "8px",
            }}
            placeholder="Description"
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
          ></textarea>
          <Button
            text={buttonText}
            isLoading={buttonLoading}
            onClick={() => handleSave()}
          />
        </Box>
      </Modal>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setButtonTextCreate("Submit");
          setOpenCreateModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: "20px" }}
            >
              Create New Annoucement
            </Typography>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setButtonTextCreate("Submit");
                setOpenCreateModal(false);
              }}
            >
              <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
            </div>
          </div>
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="input"
            style={{ border: "1px solid #ccc" }}
          >
            <option value="All">All</option>
            <option value="Students">Students</option>
            <option value="Lecturers">Lecturers</option>
            <option value="Admins">Admins</option>
            <option value="Faculty">Faculty</option>
            <option value="Department">Department</option>
            <option value="Study Center">Study Center</option>
          </select>
          {category === "Faculty" && (
            <select
              onChange={(e) => setCategorySpecific(e.target.value)}
              className="input"
              style={{ border: "1px solid #ccc" }}
            >
              <option value="">Select {category}</option>
              {FACULTIES.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          )}
          {category === "Department" && (
            <select
              onChange={(e) => setCategorySpecific(e.target.value)}
              className="input"
              style={{ border: "1px solid #ccc" }}
            >
              <option value="">Select {category}</option>
              {DEPARTMENTS.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          )}
          {category === "Study Center" && (
            <select
              onChange={(e) => setCategorySpecific(e.target.value)}
              className="input"
              style={{ border: "1px solid #ccc" }}
            >
              <option value="">Select {category}</option>
              {STUDY_CENTERS.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          )}
          <input
            className="input"
            style={{ border: "1px solid #ccc" }}
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            className="input"
            style={{
              border: "1px solid #ccc",
              height: "100px",
              borderRadius: "8px",
            }}
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <Button
            text={buttonTextCreate}
            isLoading={buttonLoadingCreate}
            onClick={() => handleCreate()}
          />
        </Box>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h2 className="page-title">Annoucements</h2>
        {(profile?.user_type === "admin" ||
          profile?.user_type === "superadmin") && (
          <button
            onClick={() => setOpenCreateModal(true)}
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#1D8A50",
              backgroundColor: "transparent",
              border: "1px solid #1D8A50",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "14px",
              height: "40px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            + Create New Announcement
          </button>
        )}
      </div>
      {loading && announcementsData.length === 0 ? (
        <PageLoader open={loading} />
      ) : (
        <>
          <p className="title" style={{ marginTop: 15, color: "darkgreen" }}>
            Latest
          </p>
          {newAnnouncements.length > 0 ? (
            <>
              {newAnnouncements.map((item, index) => (
                <RenderCardItem key={index} item={item} color="#ecf5f4" />
              ))}
            </>
          ) : (
            <div className="card">
              <p className="content" style={{ textAlign: "center" }}>
                No new announcement
              </p>
            </div>
          )}
          <p className="title" style={{ marginTop: 30, color: "darkgreen" }}>
            Earlier
          </p>
          {oldAnnouncements.length > 0 ? (
            <>
              {oldAnnouncements.map((item, index) => (
                <RenderCardItem key={index} item={item} color="#fff" />
              ))}
            </>
          ) : (
            <div className="card">
              <p className="content" style={{ textAlign: "center" }}>
                No old announcement
              </p>
            </div>
          )}
        </>
      )}
    </PageWrapper>
  );
}

const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  width: "100%",
  padding: "30px",
  boxSizing: "border-box",
  minHeight: "75vh",

  "& .title": {
    fontWeight: 500,
    fontSize: 18,
    color: "#333333",
    paddingHorizontal: 16,
  },
  "& .card": {
    backgroundColor: "#ecf5f4",
    display: "flex",
    flexDirection: "column",
    gap: 0,
    minHeight: 20,
    padding: 20,
    borderRadius: 8,
    marginBottom: 4,
    border: "1px solid #ecf5f4",
  },
  "& .cardIcon": {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#1D8A50",
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .cardText": {
    width: "100%",
  },
  "& .cardDots": {
    width: 30,
  },
  "& .card-title": {
    color: "darkgreen",
    fontSize: 16,
    fontWeight: "600",
    padding: 0,
    margin: 0,
  },
  "& .content": {
    color: "darkgreen",
    fontSize: 13,
    fontWeight: 500,
    padding: 0,
    margin: 0,
  },
  "& .timestamp": {
    color: "darkgreen",
    fontSize: 14,
    marginBottom: 0,
  },
  "& .cardLayout": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  [theme.breakpoints.down("sm")]: {
    "& .cardLayout": {
      flexDirection: "column",
    },
  },
}));
