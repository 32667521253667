const initialState = {
    profile: {},
    selectedGroup: {},
};
   
export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PROFILE_DATA': 
        return {
            ...state,
            profile: action.payload,
        };
        case 'UPDATE_SELECTED_GROUP': 
        return {
            ...state,
            selectedGroup: action.payload,
        };
        
        default:
            return state;
    }
};