import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function PageLoader(props) {
    const { open } = props;

    return (
        <Backdrop
            sx={{ color: '#1D8A50', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
