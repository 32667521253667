// redux/actions/countAction.js
export const changeAnnouncementCount = (count) => {
    return {
      type: 'CHANGE_ANNOUNCEMENT_COUNT',
      payload: count,
    };
  };

  export const updateViewAnnouncement = (item) => {
    return {
      type: 'UPDATE_VIEW_ANNOUNCEMENT',
      payload: item,
    };
  };

  export const updateProfileData = (item) => {
    return {
      type: 'UPDATE_PROFILE_DATA',
      payload: item,
    };
  };

export const increment = () => {
    return {
      type: 'COUNT_INCRESE',
    };
  };
   
  export const decrement = () => {
    return {
      type: 'COUNT_DECRESE',
    };
  };