import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";

import ResourceCard from "../../components/cards/ResourceCard";
import PageLoader from "../../components/loaders/PageLoader";
import { updateHomeData, updateResourcesData } from "../../redux/actions/dataAction";

export default function PortalSectionsPage() {
    const [loading, setLoading] = useState(true);
    const resourcesData = useSelector((store) => store.data.resourcesData);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${window.backendUrl}/api/v1/key-resources`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("userToken")}`
            }
        };

        axios.request(config)
        .then((response) => {
            let resData = response.data.data;
            const radioIndex = resData.findIndex(item => item.isRadio === true);
            const [radioItem] = resData.splice(radioIndex, 1);
            resData.splice(3, 0, radioItem);
            dispatch(updateResourcesData(resData));
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    return (
        <PageWrapper>
            <h2 className="page-title">Portal Sections</h2>
            {loading && resourcesData.length === 0 ? 
                <PageLoader open={loading} />
                : 
                <>
                    <div class="item-container">
                        {resourcesData?.map((item, index) => (
                            <div class="item" key={index}>
                                <ResourceCard 
                                    _id={item._id} 
                                    name={item.resource_name} 
                                    url={item.resource_url} 
                                    iconUrl={item.resource_icon} 
                                    status={item.status}
                                    isRadio={item?.isRadio}
                                />
                            </div>
                        ))}
                    </div>
                </>
            }
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',
}));
