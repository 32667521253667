import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";

import Header from "../components/header/HeaderComponent";
import Sidebar from "../components/sidebar/SidebarComponent";
import Footer from "../components/footer/FooterComponent";

const DashboardLayout = () => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(undefined);
    
    useEffect(() => {
        setUserToken(localStorage.getItem("userToken"));
    }, []);
    
    useEffect(() => {
        if(userToken === null){
            navigate("/auth/login");
        }
    }, [userToken]);

    return (
        <LayoutWrapper>
            <Sidebar />
            <PageWrapper>
                <Header />
                <ContentWrapper>
                    <Outlet />
                </ContentWrapper>
                <Footer />
            </PageWrapper>
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-start'
}));

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    width: '100%',
    height: '100vh',
    padding: '0rem 0rem',
    transition: 'unset',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    //overflowX: 'hidden',
    paddingTop: '0',
}));

const ContentWrapper = styled('div')(() => ({
    flex: 1,
    padding: '1rem 2rem',
    // backgroundColor: '#F5F8F9',
    // padding: '1rem 2rem',
    // overflowX: 'auto',
    // overflowY: 'hidden',
    // minHeight: '80vh',
    // height: 'auto',
    // paddingBottom: '200px',
}));


export default DashboardLayout;