import React, { useState } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import SchoolIcon from "@mui/icons-material/School";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import QuizIcon from "@mui/icons-material/Quiz";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ChatIcon from "@mui/icons-material/Chat";
import HelpIcon from "@mui/icons-material/Help";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import CampaignIcon from '@mui/icons-material/Campaign';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuItem from "./Components/MenuItem";
import SubMenuItem from "./Components/SubMenuItem";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import BarChartIcon from '@mui/icons-material/BarChart';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

import LeftArrow from "../../assets/images/left-arrow.svg";
import RightArrow from "../../assets/images/right-arrow.svg";

import LogoFull from "../../assets/images/logo-full.png";
import LogoShort from "../../assets/images/logo-short.png";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const routeList = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    label: "Portal Sections",
    path: "/portal-sections",
    icon: <LibraryBooksIcon />,
  },
  {
    label: "Announcements",
    path: "/announcements",
    icon: <CampaignIcon />,
  },
  {
    label: "AI Assistant",
    path: "/ai-assistant",
    icon: <PsychologyAltIcon />,
  },
  {
    label: "Chats",
    path: "/chats",
    icon: <ForumIcon />,
  },
  {
    label: "Help",
    path: "/help",
    icon: <HelpIcon />,
  },
  {
    label: "Profile",
    path: "/profile",
    icon: <PersonIcon />,
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [currentMenu, setCurrentItem] = useState("Dashboard");
  const [openedSubmenuItem, setOpenedSubmenuItem] = useState("");
  const profile = useSelector((store) => store.user.profile);

  const handleLogout = () => {
    Swal.fire({
      //title: 'Are you sure you want to logout?',
      title: 'Logout',
      text: "Are you sure you want to logout?",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("type");
        navigate("/auth/login");
      }
    });
  };

  return (
    <SidebarWrapper collapsed={sidebarCollapsed}>
      <SidebarCloseBtn onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
        {sidebarCollapsed ? (
          <img src={RightArrow} alt="" />
        ) : (
          <img src={LeftArrow} alt="" />
        )}
      </SidebarCloseBtn>
      <SidebarLogoWrapper collapsed={sidebarCollapsed}>
        {!sidebarCollapsed ? (
          <img src={LogoFull} alt="logo" height="65px" />
        ) : (
          <img src={LogoShort} alt="logo" height="65px" />
        )}
      </SidebarLogoWrapper>
      <SidebarMenuList>
        <>
          {routeList.map((route, index) => {
            return route.children ? (
              <SubMenuItem
                Icon={route.icon}
                label={route.label}
                collapsed={sidebarCollapsed}
                onClick={() => {
                  if (openedSubmenuItem === route.label) {
                    setOpenedSubmenuItem("");
                  } else setOpenedSubmenuItem(route.label);
                  if (sidebarCollapsed) {
                    setSidebarCollapsed(false);
                  }
                }}
                open={openedSubmenuItem === route.label}
                key={index}
              >
                {route.children.map((childRoute, i) => (
                  <div style={{ marginLeft: "24px" }} key={i}>
                    <MenuItem
                      Icon={childRoute.icon}
                      label={childRoute.label}
                      selected={currentMenu === childRoute.label}
                      collapsed={sidebarCollapsed}
                      onClick={() => {
                        setCurrentItem(childRoute.label);
                        navigate(childRoute.path);
                      }}
                    />
                  </div>
                ))}
              </SubMenuItem>
            ) : (
              <MenuItem
                  Icon={route.icon}
                  label={route.label}
                  selected={currentMenu === route.label}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem(route.label);
                    setOpenedSubmenuItem("");
                    navigate(route.path);
                  }}
                  key={index}
                />
            );
          })}
          {
          (profile?.user_type === "superadmin" || profile?.user_type === "admin") && 
          <>
            <SubMenuItem
              Icon={<BarChartIcon />}
              label={"Analytics"}
              collapsed={sidebarCollapsed}
              onClick={() => {
                if (openedSubmenuItem === "Analytics") {
                  setOpenedSubmenuItem("");
                } else setOpenedSubmenuItem("Analytics");
                if (sidebarCollapsed) {
                  setSidebarCollapsed(false);
                }
              }}
              open={openedSubmenuItem === "Analytics"}
            >
              <div style={{ marginLeft: "24px" }}>
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"All Analytics"}
                  selected={currentMenu === "Analytics"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Analytics");
                    setOpenedSubmenuItem("");
                    navigate("/analytics");
                  }}
                />
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"Study Centers"}
                  selected={currentMenu === "Study Centers"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Study Centers");
                    setOpenedSubmenuItem("Study Centers");
                    navigate("/analytic/studycenter");
                  }}
                />
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"Levels"}
                  selected={currentMenu === "Levels"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Levels");
                    setOpenedSubmenuItem("Levels");
                    navigate("/analytic/level");
                  }}
                />
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"Faculties"}
                  selected={currentMenu === "Faculties"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Faculties");
                    setOpenedSubmenuItem("Faculties");
                    navigate("/analytic/faculty");
                  }}
                />
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"Departments"}
                  selected={currentMenu === "Departments"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Departments");
                    setOpenedSubmenuItem("Departments");
                    navigate("/analytic/department");
                  }}
                />
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"Programmes"}
                  selected={currentMenu === "Programmes"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Programmes");
                    setOpenedSubmenuItem("Programmes");
                    navigate("/analytic/courseofstudy");
                  }}
                />
                <MenuItem
                  Icon={<BarChartIcon />}
                  label={"Staff"}
                  selected={currentMenu === "Staff"}
                  collapsed={sidebarCollapsed}
                  onClick={() => {
                    setCurrentItem("Staff");
                    setOpenedSubmenuItem("Staff");
                    navigate("/analytic/staff");
                  }}
                />
              </div>
            </SubMenuItem>
          <MenuItem
            Icon={<CampaignIcon />}
            label={"Welcome Note"}
            selected={currentMenu === "Welcome Note"}
            collapsed={sidebarCollapsed}
            onClick={() => {
              setCurrentItem("Welcome Note");
              setOpenedSubmenuItem("");
              navigate("/welcome-note");
            }}
          />
          <MenuItem
            Icon={<PeopleIcon />}
            label={"All Users"}
            selected={currentMenu === "All Users"}
            collapsed={sidebarCollapsed}
            onClick={() => {
              setCurrentItem("All Users");
              setOpenedSubmenuItem("");
              navigate("/all-users");
            }}
          />
          <MenuItem
            Icon={<PeopleIcon />}
            label={"Students (OnBoarded)"}
            selected={currentMenu === "Students (OnBoarded)"}
            collapsed={sidebarCollapsed}
            onClick={() => {
              setCurrentItem("Students (OnBoarded)");
              setOpenedSubmenuItem("");
              navigate("/students-onboarded");
            }}
          />
          </>
          }
        </>
        
        {
          profile?.user_type === "superadmin" && 
          <>
          <MenuItem
            Icon={<PeopleIcon />}
            label={"Active Users"}
            selected={currentMenu === "Active Users"}
            collapsed={sidebarCollapsed}
            onClick={() => {
              setCurrentItem("Active Users");
              setOpenedSubmenuItem("");
              navigate("/active-users");
            }}
          />
          <MenuItem
            Icon={<AttachMoneyIcon />}
            label={"Payments"}
            selected={currentMenu === "Payments"}
            collapsed={sidebarCollapsed}
            onClick={() => {
              setCurrentItem("Payments");
              setOpenedSubmenuItem("");
              navigate("/payments");
            }}
          />
          <MenuItem
            Icon={<LocalAtmIcon />}
            label={"Loans"}
            selected={currentMenu === "Loans"}
            collapsed={sidebarCollapsed}
            onClick={() => {
              setCurrentItem("Loans");
              setOpenedSubmenuItem("");
              navigate("/loans");
            }}
          />
          </>
        }
        <DownMenuList>
          <MenuItem
            Icon={<LogoutIcon />}
            label="Logout"
            //selected={currentMenu === "Logout"}
            collapsed={sidebarCollapsed}
            onClick={() => handleLogout()}
            selected={true}
          />
        </DownMenuList>
      </SidebarMenuList>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled("div")(({ collapsed }) => ({
  height: "100%",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  width: `${collapsed ? "5rem" : "17rem"}`,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  zIndex: 999,
}));

const SidebarLogoWrapper = styled("div")(({ collapsed }) => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  borderBottom: "1px solid #E1E1E1",
  display: `${collapsed ? "flex" : ""}`,
  justifyContent: `${collapsed ? "center" : ""}`,
}));

const SidebarMenuList = styled("div")(() => ({
  marginTop: "24px",
  flex: 1,
  justifycontent: "space-between",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
}));

const DownMenuList = styled(Box)`
  border-top: 0.87px solid #ebebeb;
  border: 0px solid red;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 0rem;
  margin: 24px 0 0;
`;

const SidebarCloseBtn = styled(Box)`
  position: absolute;
  right: -1.2rem;
  border: 1px solid #ebebeb;
  top: 22px;
  width: 37px;
  height: 31px;
  border-radius: 8px;
  background: #f3f5f9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
  &:hover {
    background: #ebebeb;
  }
  > img {
    width: 6px;
    height: 11px;
  }
`;

export default Sidebar;
