import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ChatWindow from './components/ChatWindow';
import ChatHistory from './components/ChatHistory';
import './Style.css';
import { styled } from "@mui/system";

export default function AIAssistancePage() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessage = {
        id: uuidv4(),
        text: input,
        timestamp: new Date().toLocaleTimeString()
      };
      setMessages([...messages, newMessage]);
      setInput('');
    }
  };
  return (
    <PageWrapper>
      <h2>AI Assistant</h2>
      <div className="chat-container">
        <ChatWindow messages={messages} input={input} setInput={setInput} handleSendMessage={handleSendMessage} />
        <ChatHistory messages={messages} />
      </div>
    </PageWrapper>
  );
}

const PageWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  width: "100%",
  padding: "30px",
  boxSizing: "border-box",
  minHeight: "75vh",

}));
