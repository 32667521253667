// redux/reducers/countReducer.js
const initialState = {
    count: 0,
    announcementCount: 0,
    viewAnnouncement: {},
  };
   
  export default (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_ANNOUNCEMENT_COUNT': 
        return {
            ...state,
            announcementCount: action.payload,
        };
        case 'UPDATE_VIEW_ANNOUNCEMENT': 
        return {
            ...state,
            viewAnnouncement: action.payload,
        };
        case 'COUNT_INCRESE':
            return {
                ...state,
                count: state.count + 1,
            };
        case 'COUNT_DECRESE':
            return {
                ...state,
                count: state.count - 1,
            };
        default:
            return state;
    }
  };