import Swal from "sweetalert2";
import "./CustomTable.css";
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const CustomTable = ({ columns, fetchData, submitSearch }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchColumn, setSearchColumn] = useState('name');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);

  const extraSearchTitles = useSelector((store) => store.analytics.extraSearchTitles);
  

  useEffect(() => {
    const fetchDataAsync = async () => {
      const resultData = await fetchData(currentPage, itemsPerPage, searchQuery);
      setData(resultData?.data);
      setTotal(resultData?.total);
      setIsComplete(resultData?.isComplete);
      setLoading(false);
      console.log(resultData);
    };

    fetchDataAsync();
  }, [fetchData, currentPage, itemsPerPage, searchQuery]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const fetchNewData = async (currentPage, itemsPerPage, searchQuery) => {
    setLoading(true);
    const resultData = await fetchData(currentPage, itemsPerPage, searchQuery);
    setData(resultData?.data);
    setLoading(false);
  };

  const handleSubmitSearch = async (searchColumn, searchQuery) => {
    if(isComplete){
      Swal.fire({
        title: "Error!",
        text: "This is the complete data, no need to search backend!",
        icon: "error",
        confirmButtonText: "Close",
      });
    } else {
      if(!searchColumn || !searchQuery){
        Swal.fire({
          title: "Error!",
          text: "Search and column are required!",
          icon: "error",
          confirmButtonText: "Close",
        });
      } else {
        setLoading(true);
        const searchData = await submitSearch(searchColumn, searchQuery);
        setSearchResult(searchData);
        setIsSearch(true);
        setLoading(false);
      }
    }
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = data?.filter((row) =>
      columns.some((column) => row[column.field].toString().toLowerCase().includes(query.toLowerCase()))
    );

    setFilteredData(filtered);
  };

  const handlePagination = async (action) => {
    switch (action) {
      case 'first':
        setCurrentPage(1);
        await fetchNewData(1, itemsPerPage, "");
        break;
      case 'previous':
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        await fetchNewData((prevPage) => Math.max(prevPage - 1, 1), itemsPerPage, "");
        break;
      case 'next':
        setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(total / itemsPerPage)));
        await fetchNewData((prevPage) => Math.min(prevPage + 1, Math.ceil(total / itemsPerPage)), itemsPerPage, "");
        break;
      case 'last':
        setCurrentPage(Math.ceil(total / itemsPerPage));
        await fetchNewData(Math.ceil(total / itemsPerPage), itemsPerPage, "");
        break;
      default:
        break;
    }
  };

  const handleItemsPerPageChange = async (e) => {
    setItemsPerPage(parseInt(e.target.value));
    await fetchNewData(currentPage, parseInt(e.target.value), "");
  };
  
  return (
    <div>
      <div style={{paddingBottom: 20,}}>
        {extraSearchTitles?.map((item, index) => (<h3 style={{margin: 0, padding: 0}} key={index}>{item}</h3>))}
      </div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginVertical: 20,}}>
        <h3>Total: {(isSearch ? searchResult.length : total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
        <div style={{width: "50%", display: "flex", flexDirection: "row", gap: 10,}}>
            <input type="text" placeholder="Search..." className='input' onChange={handleSearch} />
            <select className="select-input" onChange={(e) => setSearchColumn(e.target.value)} value={searchColumn}>
              {columns.map((column) => (
                <option value={column.field}>{column.header}</option>
              ))}
            </select>
            <button className='button' disabled={loading} onClick={() => handleSubmitSearch(searchColumn, searchQuery)}>{loading ? "Loading..." : "Search"}</button>
        </div>
      </div>
      <div style={{overflow: "auto"}}>
        <table border="0" width="100%" cellPadding={5} cellSpacing={0}>
          <thead>
            <tr className="head-row">
              <th>S/N</th>
              {columns.map((column) => (
                <th key={column.field} onClick={() => handleSort(column.field)} style={{cursor: "pointer", textAlign: "left"}}>
                  {column.header}
                  {sortColumn === column.field && <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              loading ?
              <tr>
                <td colSpan={columns.length + 1}><h3 style={{textAlign: "center"}}><i>Loading...</i></h3></td>
              </tr>
              :
              <>
                {(isSearch ? searchResult : data)?.filter( item => 
                  columns.some(column => {
                    const value = item[column.field];
                    return typeof value === 'string' &&
                      value.toLowerCase().includes(searchQuery.toLowerCase());
                  })
                ).sort((a, b) => {
                  if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
                  if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
                  return 0;
                }).map((row, index) => (
                  <tr key={index}>
                    <td className={index%2 === 1 ? 'even-row' : 'odd-row'}>{(itemsPerPage * (currentPage - 1)) + (index + 1)}</td>
                    {columns.map((column) => (
                      <td key={column.field} className={index%2 === 1 ? 'even-row' : 'odd-row'}>{row[column.field]}</td>
                    ))}
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
      </div>
      {!isSearch &&
        <>
        {!isComplete &&
        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 5, marginTop: 20,}}>
          <button className="pagination-button" onClick={() => handlePagination('first')}>First</button>
          <button className="pagination-button" onClick={() => handlePagination('previous')}>Previous</button>
          <span>{currentPage}</span>
          <button className="pagination-button" onClick={() => handlePagination('next')}>Next</button>
          <button className="pagination-button" onClick={() => handlePagination('last')}>Last</button>
          <select className="pagination-select" onChange={handleItemsPerPageChange} value={itemsPerPage}>
            <option value="5">5 per page</option>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        }
        </>
      }
    </div>
  );
};

export default CustomTable;
