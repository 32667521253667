import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Swal from "sweetalert2";

import Button from "../../components/button/Button";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function WelcomeNotePage() {
  const profile = useSelector((store) => store.user.profile);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({});

  const [buttonLoading, setButtonLoading] = useState(false);
  const [welcomeId, setWelcomeId] = useState("");
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${window.backendUrl}/api/v1/super-admin/welcome-note`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
        setWelcomeId(result.data._id);
        setTitle(result.data.title);
        setContent(result.data.paragraphs.join('\n\n'));
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSubmit = () => {
    if(!title || !content || !image){
      Swal.fire({  
        title: "Error",  
        icon: 'error',   
        text: "All fields are required!",  
      });
    } else {
      setButtonLoading(true);
      const paragraphsArray = content.split('\n').filter(paragraph => paragraph.trim() !== '');
      
      const formData  = new FormData();
      formData.append('file', image);
      formData.append('welcomeId', welcomeId);
      formData.append('title', title);
      formData.append('status', 1);
      formData.append('paragraphs', JSON.stringify(paragraphsArray));

      fetch(`${window.backendUrl}/api/v1/super-admin/welcome-note`, {
        method: 'POST',
        body: formData,
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("userToken")}`
        }
      })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        setButtonLoading(false);
        if (json.status === 'OK') {
          Swal.fire({  
            title: json.title,  
            icon: 'success',   
            text: json.message,  
          });
        } else {
          console.log(json);
          Swal.fire({  
            title: json.title,  
            icon: 'error',   
            text: json.message,  
          }); 
        }
        setButtonLoading(false);
      }, []);
    }
  }

  return (
    <PageWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <input type="file" style={{border: "1px solid lightgray"}} className="input" onChange={(e) => setImage(e.target.files[0])} />
          <input type="text" style={{border: "1px solid lightgray"}} className="input" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
          <textarea
            className="input"
            placeholder={"Content"}
            placeholderpColor="gray"
            style={{height: 100, borderRadius: 4, border: '1px solid #d3d3d3', justifyContent: 'flex-start'}}
            autoCapitalize="none"
            keyboardType="default"
            returnKeyType="next"
            multiline="yes"
            onChange={(e) => setContent(e.target.value)} 
            defaultValue={content}
            ></textarea>
            <Button text="Submit" isLoading={buttonLoading} onClick={() => handleSubmit()} />
        </Box>
      </Modal>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={6} sm={6} md={6}>
          <h2 className="page-title">Welcome Note</h2>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Button onClick={handleOpen} text="Edit Welcome Note" />
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={6} sm={6} md={6}>
          <img src={`${window.backendUrl}/${data?.imageUrl}`} alt="VC" width="100%" />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <h2>{data?.title}</h2>
          {
            data?.paragraphs?.map((item, index) => (
              <p key={index}>{item}</p>
            ))
          }
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

const PageWrapper = styled("div")(() => ({
  backgroundColor: "#fff",
  width: "100%",
  padding: "30px",
  boxSizing: "border-box",
  minHeight: "75vh",
  "& .title": {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
    paddingTop: 0,
  },
}));
