import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function AnalyticsSkeleton() {
    return(
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Skeleton variant="rectangular" width={"100%"} height={250} />
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{marginTop: 20}}>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="rectangular" width={"100%"} height={300} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton variant="rectangular" width={"100%"} height={300} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: 20}}>
                <Grid item xs={12} md={12}>
                    <Skeleton variant="rectangular" width={"100%"} height={250} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: 20}}>
                <Grid item xs={12} md={12}>
                    <Skeleton variant="rectangular" width={"100%"} height={250} />
                </Grid>
            </Grid>
        </Box>
    );
}