import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomTable from '../../components/table/CustomTable';
import { updateSearchData, updateSearchMemory, updateExtraSearchTitles } from '../../redux/actions/analyticsAction';

export default function AllUserTab3Page() {
  const profile = useSelector((store) => store.user.profile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchMemory = useSelector((store) => store.analytics.searchMemory);
    const searchData = useSelector((store) => store.analytics.searchData);
    const extraSearchTitles = useSelector((store) => store.analytics.extraSearchTitles);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        dispatch(updateSearchMemory(null));
        dispatch(updateSearchData([]));
        dispatch(updateExtraSearchTitles([]));
    }, []);

    useEffect(() => {
        if(profile.user_type !== "superadmin"){
            navigate("/");
        }
    }, [profile]);

    const fetchData = async (page, limit) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
        
        const raw = JSON.stringify({
          "page": page,
          "limit": limit
        });
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        try {
            const response = await fetch(`${window.backendUrl}/api/v1/data/staff`, requestOptions);
            const result = await response.json();
            console.log(result);
            setTotal(result?.data?.total);
            return result?.data;
        } catch (error) {
            console.error(error);
            return {};
        }
    };

    const submitSearch = async (searchColumn, searchQuery) => {
        if(searchMemory !== null){
          if(searchMemory[searchColumn]){
            return searchData;
          } else {
            const newSearchData = searchData.filter(item => item[searchColumn] === searchQuery);
            let searchMemoryTemp = searchMemory;
            searchMemoryTemp[searchColumn] = {
              "title": searchData[0][searchColumn],
              "total": newSearchData.length
            }
            let searchTitlesTemp = extraSearchTitles;
            searchTitlesTemp.push(`${newSearchData[0][searchColumn]} (${newSearchData?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`);
            dispatch(updateSearchMemory(searchMemoryTemp));
            dispatch(updateExtraSearchTitles(searchTitlesTemp))
            dispatch(updateSearchData(newSearchData));
            return newSearchData;
          }
        } else {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
          
          const raw = JSON.stringify({
            searchColumn,
            searchQuery
          });
    
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };
    
          try {
              const response = await fetch(`${window.backendUrl}/api/v1/data/search-staff`, requestOptions);
              const result = await response.json();
              console.log(result);
              if(result.data.length > 0){
                let searchMemory = {};
                searchMemory[searchColumn] = {
                  "title": result?.data[0][searchColumn],
                  "total": result.data.length
                }
                dispatch(updateSearchMemory(searchMemory));
                console.log(result.data[0]);
                dispatch(updateExtraSearchTitles([`${result.data[0][searchColumn]} (${result?.data?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`]))
                dispatch(updateSearchData(result.data));
              }
              return result.data;
          } catch (error) {
              console.error(error);
              return {};
          }
        }
    }

  return (
    <PageWrapper>
            <div style={{display: "flex", flexDirection: "row", gap: 20,}}>
                <h2 className="page-title">Staff ({total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</h2>
            </div>
            
            <CustomTable 
                columns = {[
                  { field: "name", header: "Name", width: 500},
                  { field: "email", header: "Email", width: 400 },
                  { field: "phone", header: "Phone", width: 400 },
                  { field: "user_type", header: "Type", width: 400 },
                  { field: "study_center", header: "Study Center", width: 500},
                ]}
                fetchData={fetchData}
                submitSearch={submitSearch} 
            />
        </PageWrapper>
  );
}

const PageWrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  width: '100%',
  padding: '0px',
  boxSizing: 'border-box',
  minHeight: '75vh',
}));
