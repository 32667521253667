import React from "react";
import { styled } from "@mui/system";
import { IconButton, Typography, Paper } from "@mui/material";
import { PhotoCamera, InsertDriveFile } from "@mui/icons-material";

const Wrapper = styled(Paper)`
  width: 150px;
  height: fit-content;
  position: absolute;
  left: ${ props => `${props.position.x}px`};
  top: ${ props => `${props.position.y - 180}px`};
`;

const ShareFileModal = ({ openCamera, pickImage, pickDocument, position }) => {
  return (
    <Wrapper position={position}>
      <Paper>
        <div style={{ display: "flex", flexWrap: "wrap", padding: "4px" }}>
          <IconButton onClick={{}}>
            <PhotoCamera fontSize="large" />
            <Typography>Camera</Typography>
          </IconButton>
          <IconButton onClick={pickDocument}>
            <PhotoCamera fontSize="large" />
            <Typography>Photos</Typography>
          </IconButton>
          <IconButton onClick={pickDocument}>
            <InsertDriveFile fontSize="large" />
            <Typography>Documents</Typography>
          </IconButton>
        </div>
      </Paper>
    </Wrapper>
  );
};

export default ShareFileModal;
