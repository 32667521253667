const initialState = {
    profile: {},
    selectedGroup: {},
    homeData: {},
    resourcesData: [],
    announcementsData: [],
};
   
export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_HOME_DATA': 
        return {
            ...state,
            homeData: action.payload,
        };
        case 'UPDATE_RESOURCES_DATA': 
        return {
            ...state,
            resourcesData: action.payload,
        };
        case 'UPDATE_ANNOUNCEMENTS_DATA': 
        return {
            ...state,
            announcementsData: action.payload,
        };
        
        default:
            return state;
    }
};