import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyDRLNg_3LLUBTXlOhMc-FSqc8A6y5BVfnc",
    authDomain: "noun-project-9db35.firebaseapp.com",
    databaseURL: "https://noun-project-9db35-default-rtdb.firebaseio.com",
    projectId: "noun-project-9db35",
    storageBucket: "noun-project-9db35.appspot.com",
    messagingSenderId: "657623546932",
    appId: "1:657623546932:web:c66eb837a7ba208721de61",
    measurementId: "G-JF24Q9SRRB"
  };

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const storage = getStorage(app);

  export { app, database, storage };