import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/system";
import axios from "axios";
import {
  ref,
  push,
  onValue,
  off,
  get,
  query,
  orderByChild,
  startAt,
  endAt,
  update,
  remove,
} from "firebase/database";
import {
  ref as sRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { DeleteForever, Mic, Add } from '@mui/icons-material';
import { database,storage } from "../../firebase";
import { updateSelectedGroup } from "../../redux/actions/userAction";
import Player from "../../components/chats/player";
import DeleteMessageModal from "../../components/chats/DeleteMessageModal";
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import VoiceNoteModal from "../../components/chats/voiceNoteModal";
import ShareFileModal from "../../components/chats/shareFile";

const IMAGE_MIME_TYPES = ["image", "image/jpeg", "image/png", "png", "JPG"];

export default function ChatsPage() {
  const profile = useSelector((store) => store.user.profile);
  const selectedGroup = useSelector((store) => store.user.selectedGroup);

  const dispatch = useDispatch();

  const [chatGroups, setChatGroups] = useState([]);
  const [chatPrivate, setChatPrivate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPrivate, setLoadingPrivate] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [activeTab, setActiveTab] = useState('group');
  const [searchQueryGroups, setSearchQueryGroups] = useState('');
  const [searchQueryPrivate, setSearchQueryPrivate] = useState('');
  const [currentPlayingId, setCurrentPlayingId] = useState(null);
  const [currentSound, setCurrentSound] = useState(null);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const [isVoiceNotePaused, setIsVoiceNotePaused] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const getPageData = async () => {
    try {
      const response = await axios.get(
        `${window.backendUrl}/api/v1/chats/groups`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        },
      );

      const groups = response?.data?.data;
      dispatch(updateSelectedGroup(groups[0]));

      // Create an array of promises for fetching group data
      const groupPromises = groups.map(async (group) => {
        const groupId = group.name;
        const userId = profile?._id;
        const lastOpenedTimestamp = await getLastOpenedTimestamp(
          groupId,
          userId,
        );
        const startDate =
          lastOpenedTimestamp === null
            ? "06/01/2023 00:00 AM"
            : lastOpenedTimestamp;
        const endDate =
          new Date().toLocaleDateString() +
          " " +
          new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

        const messagesRef = ref(database, `chats/${groupId}/messages`);
        const queryRef = query(
          messagesRef,
          orderByChild("createdAt"),
          startAt(startDate),
          endAt(endDate),
        );
        try {
          const snapshot = await get(queryRef);

          let tempData = [];
          let unreadCount = 0;
          if (snapshot.exists()) {
            const messages = await snapshot.val();
            for (const messageId in messages) {
              const message = messages[messageId];
              if (message.senderId !== userId) {
                unreadCount++;
              }
            }
            const lastMessage = Object.values(messages).pop();
            //const size = Object.keys(messages).length;
            tempData.push({
              lastMessage: lastMessage?.text,
              name: groupId,
              time: lastMessage?.sendTime,
              unreadCount: unreadCount,
            });
          } else {
            tempData.push({
              lastMessage: "No new message",
              name: groupId,
              time: "",
              unreadCount: 0,
            });
          }
          return tempData;
        } catch (error) {
          console.error("Error querying messages:", error);
          //Alert.alert('Error', 'Failed to get groups!');
        }
      });

      // Wait for all promises to resolve
      const chatGroupData = await Promise.all(groupPromises);

      // Flatten the array of arrays into a single array
      const chatGroups = chatGroupData.flat();

      setChatGroups(chatGroups);
      setLoading(false);
    } catch (error) {
      console.log(error);
      //Alert.alert('Error', 'Failed to get groups!');
      setLoading(false);
    }
  };

  const getPrivateData = async () => {
    try {
      const response = await axios.get(`${window.backendUrl}/api/v1/chats/private`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("userToken")}`
        }
      });
  
      const groups = response?.data?.data;
  
      // Create an array of promises for fetching group data
      const groupPromises = groups.map(async (group) => {
        const userId = profile?._id;
        const groupMessagesRefCheck = ref(database, `chats-private/${group._id}-${userId}/messages`);
        let groupId;
        await get(groupMessagesRefCheck)
        .then((snapshot) => {
          if (snapshot.exists()) {
            // Data exists at the specified location
            //console.log("Record exists.");
            groupId = `${group._id}-${userId}`;
          } else {
            // Data doesn't exist at the specified location
            //console.log("Record does not exist.");
            groupId = `${userId}-${group._id}`;
          }
        })
        .catch((error) => {
          console.error("Error reading data:", error);
        });
        //const groupId = `${group._id}-${userId}`;
        
        const lastOpenedTimestamp = await getLastOpenedTimestamp(groupId, userId);
        const startDate = lastOpenedTimestamp === null ? "06/01/2023 00:00 AM" : lastOpenedTimestamp;
        const endDate = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  
        const messagesRef = ref(database, `chats-private/${groupId}/messages`);
        const queryRef = query(
          messagesRef,
          orderByChild("createdAt"),
          startAt(startDate),
          endAt(endDate)
        );
        
        try {
          const snapshot = await get(queryRef);
          
          let tempData = [];
          let unreadCount = 0;
          if (snapshot.exists()) {
            const messages = await snapshot.val();
            for (const messageId in messages) {
              const message = messages[messageId];
              if (message.senderId !== userId) {
                unreadCount++;
              }
            }
            const lastMessage = Object.values(messages).pop();
            //const size = Object.keys(messages).length;
            tempData.push({
              "lastMessage": lastMessage?.text,
              "name": group.name,
              "time": lastMessage?.sendTime,
              "unreadCount": unreadCount,
              "senderId": lastMessage?.senderId,
              "recipientId": group?._id
            });
          } else {
            tempData.push({
              "lastMessage": 'No new message',
              "name": group.name,
              "time": '',
              "unreadCount": 0,
              "senderId": '',
              "recipientId": group?._id
            });
          }
          return tempData;
        } catch (error) {
          console.error("Error querying messages:", error);
          //Alert.alert('Error', 'Failed to get groups!');
        }
      });
  
      // Wait for all promises to resolve
      const chatGroupData = await Promise.all(groupPromises);
  
      // Flatten the array of arrays into a single array
      const chatPrivate = chatGroupData.flat();
      console.log(chatPrivate);
      setChatPrivate(chatPrivate);
      setLoadingPrivate(false);
    } catch (error) {
      console.log(error);
      //Alert.alert('Error', 'Failed to get groups!');
      setLoadingPrivate(false);
    }
  };

  useEffect(() => {
    getPageData();
    getPrivateData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastOpenedTimestamp = async (groupId, userId) => {
    try {
      const userLastOpenedRef = ref(
        database,
        `userLastOpened/${userId}/${groupId}`,
      );
      const snapshot = await get(userLastOpenedRef);

      if (snapshot.exists()) {
        const lastOpenedTimestamp = snapshot.val();
        return lastOpenedTimestamp;
      } else {
        // Handle the case where the data doesn't exist (user has not opened the group before)
        return null;
      }
    } catch (error) {
      // Handle any errors that occur during the database query
      console.error("Error getting lastOpenedTimestamp:", error);
      return null;
    }
  };

  const handleChatClick = (item, type) => {
    dispatch(updateSelectedGroup(item));
    if(type === 'group'){
      setGroupId(item?.name);
      setChatType(type);
      setChatId(item?.id);
    } else {
      setGroupId(item?.name);
      setChatType(type);
      setChatId(item?.recipientId);
    }
  }

  const filteredChatGroups = chatGroups.filter((group) => group?.name?.toLowerCase().includes(searchQueryGroups.toLowerCase()));
  const filteredChatPrivate = chatPrivate.sort((a, b) => b.unreadCount - a.unreadCount).filter((group) => group?.name?.toLowerCase().includes(searchQueryPrivate.toLowerCase()));

  const handleSearchQuery = (text) => {
    if(activeTab === "private"){
      setSearchQueryPrivate(text);
    } else {
      setSearchQueryGroups(text);
    }
  }

  let totalUnreadCountGroups = 0;
  for (const item of chatGroups) {
    totalUnreadCountGroups += item.unreadCount;
  }

  let totalUnreadCountPrivate = 0;
  for (const item of chatPrivate) {
    totalUnreadCountPrivate += item.unreadCount;
  }



  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [position, setPosition] = useState({x:0, y:0})
  const inputRef = useRef(null);
  const bottomRef = useRef(null);
  const [groupId, setGroupId] = useState('');
  const [chatType, setChatType] = useState('');
  const [chatId, setChatId] = useState('');
  const [currentPrivate, setCurrentPrivate] = useState(null);
  const [showRecording, setShowRecording] = useState(false);
  const shareFileRef = useRef()

  useEffect(() => {
    // Define the outside click handler
    const handleClickOutside = (event) => {
      if (
        shareFileRef.current &&
        !shareFileRef.current.contains(event.target)
      ) {
        setIsShareModalOpen(false);
      }
    };

    // Attach the handler for click event
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updateLastOpenedTimestamp = (groupId) => {
    const groupIdName = chatType === 'private' ? currentPrivate : groupId;
    const userLastOpenedPath = `userLastOpened/${profile?._id}/${groupIdName}`;
    const timestamp = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    try {
      const dataToUpdate = {
        [`${userLastOpenedPath}`]: timestamp,
      };
      update(ref(database), dataToUpdate);
    } catch(error) {
      console.log('AGM => ', error);
    }
  };

  useEffect(() => {
    const groupMessagesRefCheck = ref(database, `chats-private/${chatId}-${profile?._id}/messages`);
    if(chatType === 'private'){
      get(groupMessagesRefCheck)
      .then((snapshot) => {
        if (snapshot.exists()) {
          // Data exists at the specified location
          console.log("Record exists.");
          setCurrentPrivate(`${chatId}-${profile?._id}`);
        } else {
          // Data doesn't exist at the specified location
          console.log("Record does not exist.");
          setCurrentPrivate(`${profile?._id}-${chatId}`);
        }
      })
      .catch((error) => {
        console.error("Error reading data:", error);
      });
    }
  }, [chatType, chatId, profile?._id]);

  const handleSendMessage = () => {
    if(inputText){
      const newMessage = {
        id: messages.length + 1,
        text: inputText,
        senderName: profile?.name,
        senderId: profile?._id,
        sendTime: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        createdAt: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        currentPrivate: currentPrivate,
      };
      try {
        const groupPath = chatType === 'private' ? `chats-private/${currentPrivate}` : `chats/${groupId}`;
      
        // Push the new message to the specified group's messages node.
        push(ref(database, `${groupPath}/messages`), newMessage);
        setInputText('');
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      } catch(error) {
        console.log('AGM => error: ', error)
      }
    }
  };

  useEffect(() => {
    if(chatType === 'private'){
      let listenToMessagesForGroup = (currentPrivate, callback) => {
        const groupMessagesRefCheck = ref(database, `chats-private/${currentPrivate}/messages`);
        let listener = onValue(groupMessagesRefCheck, (snapshot) => {
          try{
            const messages = [];
            snapshot.forEach((childSnapshot) => {
              messages.push({firebaseId: childSnapshot.key, node: childSnapshot.val()});
            });
            callback(messages);
          } catch(e){
            console.log('AGM => e: ', e);
          }
        });

        return () => {
          off(listener);
        };
      };

      listenToMessagesForGroup(currentPrivate, (messages) => {
        setMessages(messages);
      });
    } else {
      let listenToMessagesForGroup = (groupId, callback) => {
        const groupMessagesRef = ref(database, chatType === 'private' ? `chats-private/${groupId}/messages` : `chats/${groupId}/messages`);
        let listener = onValue(groupMessagesRef, (snapshot) => {
          try{
            const messages = [];
            snapshot.forEach((childSnapshot) => {
              messages.push({firebaseId: childSnapshot.key, node: childSnapshot.val()});
            });
            callback(messages);
          } catch(e){
            console.log('AGM => e: ', e);
          }
        });

        return () => {
          off(listener);
        };
      };

      listenToMessagesForGroup(groupId, (messages) => {
        setMessages(messages);
      });
    }
  }, [chatType, groupId, currentPrivate]);

  useEffect(() => {
    if(chatType === 'private'){
      if(currentPrivate){
        updateLastOpenedTimestamp(groupId);
      }
    } else {
      updateLastOpenedTimestamp(groupId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType, groupId, currentPrivate]);

  useEffect(() => {
    updateLastOpenedTimestamp(groupId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const pauseVoiceNote = async () => {
    try {
      if (currentSound) {
        await currentSound.pause();
        setHasStartedPlaying(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const resumeVoiceNote = async () => {
    setIsVoiceNotePaused(false);
    try {
      if (currentSound) {
        await currentSound.play();
        setHasStartedPlaying(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const playVoiceNote = async (audio, id) => {
    try {
      // If there's a voice note currently playing, stop it
      await pauseVoiceNote();
      setCurrentPlayingId(id);
      const audioElement = new Audio(audio);
      audioElement.addEventListener('loadeddata', () => {
        // Audio has loaded, update your UI state here
        setHasStartedPlaying(true);
      });
      audioElement.addEventListener('ended', () => {
        // Audio has finished playing
        setCurrentPlayingId(null);
        setHasStartedPlaying(false);
      });
      await audioElement.play();
      setCurrentSound(audioElement);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleDeleteMessage(id) {
    try {
      const messagePath =
        chatType === "private"
          ? `chats-private/${currentPrivate}/${id}`
          : `chats/${groupId}/messages/${id}`;
      const messageRef = ref(database, messagePath);
      await remove(messageRef);
      setDeleteModalData(null);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  }

  const pickDocument = async () => {
    try {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '*/*'; // Allow all types of files
      input.onchange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        try {
          setIsSending(true);
          const storageRef = sRef(
            storage,
            'documents/' + file.name // Adjust the storage path as needed
          );
          await uploadBytesResumable(storageRef, file, {
            contentType: file.type,
          });
          const downloadURL = await getDownloadURL(storageRef);
  
          const newMessage = {
            id: messages.length + 1,
            text: file.name,
            senderName: profile?.name,
            senderId: profile?._id,
            sendTime: new Date().toLocaleTimeString(),
            createdAt: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
            currentPrivate: currentPrivate,
            messageType: file.type,
            fileUrl: downloadURL,
          };
          const groupPath =
            chatType === 'private'
              ? `chats-private/${currentPrivate}`
              : `chats/${groupId}`;
          await push(ref(database, `${groupPath}/messages`), newMessage);
          setIsSending(false);
          setIsShareModalOpen(false);
          // const payload = { ...notificationsPayload, description: 'document' };
          // sendNotification(payload);
        } catch (error) {
          setIsSending(false);
          console.error('Error uploading document:', error);
        }
      };
      input.click();
    } catch (error) {
      setIsSending(false);
      console.error('Error picking document:', error);
    }
  };
  

  const RenderMessage = ({ item }) => {
    const isCurrentUser = item?.node?.senderId === profile?._id;

    return (
      <div
        className="messageContainerDetail"
        //style={isCurrentUser ? {justifyContent: 'flex-end'} : {justifyContent: 'flex-start'}}
      >
        {!isCurrentUser && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "left",
              gap: "10px",
            }}
          >
            <div className="avatarContainer">
              <p className="avatarText">{item?.node?.senderName.charAt(0)}</p>
            </div>
            <div className="messageContentOthers">
              <div className="messageHeader">
                <p className="senderText">{item?.node?.senderName}</p>
              </div>
              {IMAGE_MIME_TYPES.some((type) => type === item?.node?.messageType) ? (
                <div>
                  <ImageZoom>
                    <img height={200} alt="photograph" src={item?.node?.fileUrl} />
                  </ImageZoom>
                </div>
              ): (
                <>
                  {
                  item?.node?.messageType === "audio" ? (
                    <Player
                      item={item}
                      currentPlayingId={currentPlayingId}
                      playVoiceNote={playVoiceNote}
                      hasStartedPlaying={hasStartedPlaying}
                      pauseVoiceNote={pauseVoiceNote}
                      resumeVoiceNote={resumeVoiceNote}
                      isVoiceNotePaused={isVoiceNotePaused}
                      setIsVoiceNotePaused={setIsVoiceNotePaused}
                    />
                  ):
                   item?.node?.fileUrl ? (
                    <a href={item?.node?.fileUrl}>{item?.node?.text}</a>
                  ) : (
                    <p className="messageText">{item?.node?.text}</p>
                  )}
                </>
              )}
              <p className="sendTimeText">{item?.node?.createdAt}</p>
            </div>
          </div>
        )}
        {isCurrentUser && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "right",
              gap: "10px",
            }}
          >
            <div className="messageContentMe">
              <div className="messageHeader">
                <p className="senderText">{item?.node?.senderName}</p>
              </div>
              {IMAGE_MIME_TYPES.some((type) => type === item?.node?.messageType) ? (
                <div>
                  <ImageZoom>
                    <img height={200} alt="photograph" src={item?.node?.fileUrl} />
                  </ImageZoom>
                </div>
              ): (
                <>
                  {
                  item?.node?.messageType === "audio" ? (
                    <Player
                      item={item}
                      currentPlayingId={currentPlayingId}
                      playVoiceNote={playVoiceNote}
                      hasStartedPlaying={hasStartedPlaying}
                      pauseVoiceNote={pauseVoiceNote}
                      resumeVoiceNote={resumeVoiceNote}
                      isVoiceNotePaused={isVoiceNotePaused}
                      setIsVoiceNotePaused={setIsVoiceNotePaused}
                    />
                  ):
                   item?.node?.fileUrl ? (
                    <a href={item?.node?.fileUrl}>{item?.node?.text}</a>
                  ) : (
                    <p className="messageText">{item?.node?.text}</p>
                  )}
                </>
              )}
               <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "8px"}}>
                <DeleteForever 
                  color="error" 
                  style={{cursor: "pointer"}}
                  onClick={() => setDeleteModalData(item)}
                />
                <span className="sendTimeText">{item?.node?.createdAt}</span>
              </div>
            </div>
            <div className="avatarContainer">
              <p className="avatarText">{item?.node?.senderName.charAt(0)}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PageWrapper>
      <div style={{}}>
        <Link to="/" style={{ textDecoration: "none", color: "#1D8A50" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "20px",
              border: "0px solid red",
            }}
          >
            <CloseIcon style={{ fontSize: "28px" }} />
            <h2 className="page-title" style={{ margin: 0, padding: 0 }}>
              Chats
            </h2>
          </div>
        </Link>
      </div>
      <div className="row" style={{ height: "90%", border: "0px solid red" }}>
        <div
          className="col-chat-group"
          style={{ border: "0px solid red", overflow: "auto" }}
        >
          <h2 className="heading">{activeTab === 'private' ? 'Private Chats' : 'Group Chats'}</h2>
          <input
            className="searchInput input"
            placeholder={activeTab === 'private' ?  "Search User..." : "Search Group..."}
            onChange={(e) => handleSearchQuery(e.target.value)}
            value={activeTab === 'private' ? searchQueryPrivate : searchQueryGroups}
          />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
            <button 
              onClick={() => {setActiveTab('group')}} 
              style={{borderBottomLeftRadius: 10, borderTopLeftRadius: 10}}
              className={activeTab === 'group' ? "tabActive" : "tab"}
              >
              Group Chats ({totalUnreadCountGroups})
            </button>
            <button 
              onClick={() => {setActiveTab('private')}} 
              style={{borderBottomRightRadius: 10, borderTopRightRadius: 10}}
              className={activeTab === 'private' ? "tabActive" : "tab"}
              >
              Private Chats ({totalUnreadCountPrivate})
            </button>
          </div>
          {
            activeTab === 'private' ? (
              <>
              {
                loadingPrivate ? (
                  <h3 style={{textAlign: 'center', fontStyle: 'italic', color: '#065E54'}}>Loading ...</h3>
                ) : (
                  <>
                  {filteredChatPrivate.map((item, index) => (
                    <div
                      key={index}
                      to=""
                      className="chatItem"
                      onClick={() => handleChatClick(item, 'private')}
                      style={
                        item?.name === selectedGroup?.name
                          ? { backgroundColor: "#ecf5f4" }
                          : {}
                      }
                    >
                      <div className="avatar">
                        <p className="avatarText">{item?.name.charAt(0)}</p>
                      </div>
                      <div className="chatContent">
                        <div className="chatHeader">
                          <p className="chatName">{item?.name}</p>
                          <p className="chatTime">{item?.time}</p>
                        </div>
                        <div className="messageContainer">
                          <p className="chatMessage">{item?.lastMessage}</p>
                          {item.unreadCount > -1 && (
                            <div className="unreadBadge">
                              <p className="unreadCount">{item?.unreadCount}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  </>
                )
              }
              </>
            ) : (
              <>
              {
                loading ? (
                  <h3 style={{textAlign: 'center', fontStyle: 'italic', color: '#065E54'}}>Loading ...</h3>
                ) : (
                  <>
                  {filteredChatGroups.map((item, index) => (
                    <div
                      key={index}
                      to=""
                      className="chatItem"
                      onClick={() => handleChatClick(item, 'group')}
                      style={
                        item?.name === selectedGroup?.name
                          ? { backgroundColor: "#ecf5f4" }
                          : {}
                      }
                    >
                      <div className="avatar">
                        <p className="avatarText">{item?.name.charAt(0)}</p>
                      </div>
                      <div className="chatContent">
                        <div className="chatHeader">
                          <p className="chatName">{item?.name}</p>
                          <p className="chatTime">{item?.time}</p>
                        </div>
                        <div className="messageContainer">
                          <p className="chatMessage">{item?.lastMessage}</p>
                          {item.unreadCount > -1 && (
                            <div className="unreadBadge">
                              <p className="unreadCount">{item?.unreadCount}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  </>
                )
              }
              </>
            )
          }
        </div>
        <div className="col-chat-message">
          <div style={{}}>
            <h2 className="heading" style={{ textAlign: "center" }}>
              {selectedGroup?.name}
            </h2>
          </div>
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "90%",
              border: "0px solid orange",
            }}
          >
            <div
              style={{
                border: "1px solid #F5F8F9",
                borderRadius: 8,
                backgroundColor: "#F5F8F9",
                padding: 20,
                overflow: "auto",
              }}
            >
              {messages.map((item, index) => (
                <RenderMessage key={index} item={item} />
              ))}
              <div ref={bottomRef} />
            </div>
            <div className="inputContainer">
              <button
                className="sendButton"
                style={{marginRight: "12px"}}
                onClick={(e) => {
                  setIsShareModalOpen(true);
                  setPosition({x: e.clientX, y: e.clientY});
                }}
              >
                <Add style={{ color: "#fff" }} />
              </button>
              <input
                className="searchInput input"
                placeholder="Type a message..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                multiline={true}
                ref={inputRef}
                style={{ marginRight: 10, marginBottom: 0 }}
              />
              {isSending ? (
                <CircularProgress style={{ color: "#1D8A50" }} size={25} />
              ): (
                <>
                  {inputText ? (
                    <button
                      className="sendButton"
                      onClick={() => handleSendMessage()}
                    >
                      <SendIcon style={{ color: "#fff" }} />
                    </button>
                  ): (
                    <button
                      className="sendButton"
                      onClick={() => setShowRecording(true)}
                    >
                      <Mic style={{ color: "#fff" }} />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div ref={shareFileRef}>
        {isShareModalOpen && <ShareFileModal position={position} pickDocument={pickDocument} />}
      </div>
      <DeleteMessageModal
        deleteModalData={deleteModalData}
        setDeleteModalData={setDeleteModalData}
        handleDeleteMessage={handleDeleteMessage}
      />
      { showRecording && (
        <VoiceNoteModal
        chatType={chatType}
        chatId={chatId}
        groupId={groupId}
        showRecording={showRecording}
        currentPrivate={currentPrivate}
        messages={messages} 
        setShowRecording={setShowRecording}
        setIsSending={setIsSending}
      />
      )}
    </PageWrapper>
  );
}

const PageWrapper = styled("div")(() => ({
  backgroundColor: "#fff",
  width: "100%",
  height: "100vh",
  padding: "30px",
  boxSizing: "border-box",
  minHeight: "75vh",

  "& .heading": {
    fontSize: 18,
    textAlign: "left",
    fontWeight: 500,
    paddingTop: 0,
    color: "#333",
  },
  "& .title": {
    fontWeight: "500",
    fontSize: 18,
    color: "#333333",
    marginVertical: 10,
  },
  "& .searchInput": {
    border: "1px solid #ccc",
    height: 40,
  },
  "& .chatItem": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
    borderBottom: "1px solid #ccc",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  "& .avatar": {
    width: 45,
    height: 45,
    borderRadius: 22.5,
    backgroundColor: "#075e54",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  "& .avatarText": {
    fontSize: 18,
    color: "#fff",
  },
  "& .chatContent": {
    flex: 1,
  },
  "& .chatHeader": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
    marginTop: "10px",
  },
  "& .chatName": {
    fontWeight: "600",
    fontSize: 16,
    color: "#333333",
    margin: 0,
    padding: 0,
  },
  "& .chatTime": {
    color: "#666",
    margin: 0,
    padding: 0,
  },
  "& .messageContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    marginBottom: "10px",
  },
  "& .chatMessage": {
    color: "#666",
    flex: 1,
    padding: 0,
    margin: 0,
  },
  "& .unreadBadge": {
    backgroundColor: "#1D8A50", // Customize badge background color
    marginLeft: 8,
    width: "20px",
    height: "20px",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    lineHeight: "20px",
  },
  "& .unreadCount": {
    color: "#fff",
    fontSize: "12px",
    fontWeight: "bold",
    padding: 0,
    margin: 0,
  },

  "& .messagesContainer": {
    paddingHorizontal: 10,
    paddingBottom: 10, // Add some padding at the bottom
  },
  "& .messageContainerDetail": {
    display: "flex",
    flexDirection: "column", // Stack sender and message vertically
    marginBottom: 10,
    width: "100%",
    border: "0px solid red",
  },
  "& .myMessageContainer": {
    alignSelf: "flex-end", // Align right for current user's messages
  },
  "& .otherMessageContainer": {
    alignSelf: "flex-start", // Align left for other user's messages
  },
  "& .avatarContainer": {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "#075e54",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 0,
  },
  // eslint-disable-next-line no-dupe-keys
  "& .avatarText": {
    fontSize: 18,
    color: "#fff",
  },
  "& .messageContentOthers": {
    borderRadius: 8,
    backgroundColor: "#fff",
    padding: 10,
    maxWidth: "75%", // Limit message width
    marginHorizontal: 10,
    borderColor: "#ECE5DD",
    borderWidth: 1,
  },
  "& .messageContentMe": {
    borderRadius: 8,
    backgroundColor: "#DCF8C6",
    padding: 10,
    maxWidth: "75%", // Limit message width
    marginHorizontal: 10,
    borderColor: "#ECE5DD",
    borderWidth: 1,
  },
  "& .messageHeader": {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    gap: 20,
  },
  "& .senderText": {
    fontSize: 14,
    fontWeight: "bold",
    color: "#075e54",
    padding: 0,
    margin: 0,
  },
  "& .sendTimeText": {
    fontSize: 12,
    color: "#999",
    textAlign: "right",
    paddingTop: 5,
    padding: 0,
    margin: 0,
  },
  "& .messageText": {
    fontSize: 16,
    padding: 0,
    margin: 0,
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    backgroundColor: "#fff",
    width: "100%",
  },
  "& .input": {
    flex: 1,
    height: 40,
  },
  "& .sendButton": {
    position: "relative",
    right: 0,
    top: 0,
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#1D8A50",
    border: "1px solid #1D8A50",
    cursor: "pointer"
  },
}));
