import React from "react";
import { styled } from "@mui/system";

export default function LoginPage(props) {
    const {text, onClick, isLoading} = props;
    return (
        <ButtonWrapper>
            <button 
                className="button" 
                disabled={isLoading}
                onClick={onClick}
                >
                {isLoading ? "Loading..." : text}
            </button>
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled('div')(() => ({
    
    '& .button': {
        color: '#fff',
        backgroundColor: '#1D8A50',
        textAlign: 'center',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        width: '100%',
        height: '50px',
        borderRadius: '50px',
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        border: 0, 
        cursor: 'pointer',
    },
    '& .button:hover': {
        backgroundColor: '#068344'
    },
}));
