export const FACULTIES = [
    "Faculty of Agricultural Sciences",
    "Faculty of Arts",
    "Faculty of Education",
    "Faculty of Health Science",
    "Faculty of Health Sciences",
    "Faculty of Law",
    "Faculty of Management Science",
    "Faculty of Management Sciences",
    "Faculty of Science",
    "Faculty of Sciences",
    "Faculty of Social Science",
    "Faculty of Social Sciences",
    "School of Postgraduate Studies"
  ];

  export const DEPARTMENTS = [
    "ACETEL",
    "Agricultural Extension and Management",
    "Animal Science",
    "Arts and Social Science Education",
    "Biology",
    "Business Administration",
    "Chemistry",
    "Computer Science",
    "Criminology and Security Studies",
    "Crop and Soil Science ",
    "Development Studies",
    "Economics",
    "Educational Foundation",
    "Entrepreneurship Studies",
    "Environmental Health",
    "Environmental Science",
    "Financial Studies",
    "Health and Kinetic Education",
    "International Relation",
    "LLB Law",
    "LLM Law",
    "Languages",
    "Library and Information Science",
    "Mass Communication",
    "Mathematics",
    "Nursing Science",
    "PGD Law",
    "Peace Studies and Conflict Resolution",
    "Philosophy",
    "Physics",
    "Political Science",
    "Public Administration",
    "Public Health",
    "Religious Studies",
    "Science Education",
    "Tourism Studies"
  ];

  export const STUDY_CENTERS = [
    "Abakaliki Study Centre ",
    "Abeokuta Correctional Service  Study Centre",
    "Abeokuta Study Centre",
    "Abuja Model Study Centre",
    "Abuja Model Study Centre ",
    "Ado-Ekiti Study Centre",
    "Akure Study Centre",
    "Asaba Study Centre ",
    "Asaga Community Study Centre",
    "Awa-Ijebu Community Study Centre",
    "Awgu Community Study Centre",
    "Awka Study Centre",
    "Azare Community Study Centre",
    "Badagry Study Centre",
    "Bagwai Study Centre",
    "Bauchi Study Centre",
    "Benin  Study Centre ",
    "Benin Study Centre ",
    "Bichi Study Centre",
    "Bogoro Community Study Centre",
    "Calabar Study Centre",
    "Damaturu Study Centre",
    "Danbatta Study Centre",
    "Dawakin Kudu Study Centre",
    "Dawakin Tofa Study Centre",
    "Dutse Study Centre ",
    "Egbe Community Study Centre",
    "Emevor Community Study Centre",
    "Enugu Study Centre ",
    "Fagge Study Centre",
    "Fugar Community Study Centre ",
    "Gabasawa Study Centre",
    "Gombe Study Centre ",
    "Gulak Community Study Centre ",
    "Gusau Study Centre ",
    "Gwarzo Study Centre",
    "Hadejia Study Centre",
    "ILESHA CORRECTIONAL SERVICE SPECIAL STUDY CENTRE",
    "Ibadan Study Centre",
    "Idah Community Study Centre ",
    "Ikom Community Study Centre",
    "Ikorodu Model Study Centre",
    "Ilaro Community Study Centre",
    "Ilesha Correctional Service Special Study Centre",
    "Ilofa Community Study Centre ",
    "Ilorin Study Centre ",
    "Isanlu Community Study Centre",
    "Isua-Akoko Community Study Centre",
    "Isulo Community Study Centre",
    "Iwo Study Centre",
    "Iyara Community Study Centre",
    "Jalingo Study Centre ",
    "Jos Study Centre",
    "Kabo Study Centre",
    "Kaduna Correctional Service Study Centre",
    "Kaduna Study Centre",
    "Kagoro Study Centre",
    "Kano Study Centre",
    "Kano Study Centre ",
    "Katsina Study Centre ",
    "Kebbi Study Centre",
    "Kisi Community Study Centre",
    "Kuje Correctional Service Study Centre",
    "Kunchi Study Centre",
    "Kwaciri Study Centre",
    "Lafia Correctional Service Study Centre",
    "Lafia Study Centre ",
    "Lagos Mainland Study Centre I",
    "Lagos Study Centre",
    "Lokoja Study Centre ",
    "Maiduguri Study Centre ",
    "Makoda Study Centre",
    "Makurdi Study Centre ",
    "Mani Community Study Centre",
    "Masari Community Study Centre",
    "Mccarthy Study Centre Lagos",
    "Mccarthy Study Centre Obalende",
    "Minna Study Centre ",
    "Mushin Study Centre Lagos",
    "NOUN HQ",
    "National Union Of Road Transport Workers Special Study Centre Garki Abuja",
    "Nigeria Army Special Study Centre Ilorin",
    "Nigeria Correctional Service Special Study Centre Awka",
    "Nigeria Correctional Service Special Study Centre Ikoyi",
    "Nigeria Correctional Service Special Study Centre Keffi",
    "Nigeria Immigration Service Special Study Centre Abuja",
    "Nigeria Police Special Study Centre Kubwa Abuja",
    "Nigeria Security And Civil Defence Special Study Centre Sauka Abuja",
    "Nigerian Air Force Special Study Centre Kaduna",
    "Nigerian Correctional Service Special Study Centre Enugu",
    "Nigerian Correctional Service Special Study Centre Port-Harcourt",
    "Nigerian Female Correctional Service Special Study Centre Kirikiri",
    "Nigerian Maximum Security Correctional Service Special Study Centre Kirikiri",
    "Nigerian Medium Security Correctional Service Special Study Centre Kirikiri Lagos",
    "Nsu Community Study Centre ",
    "Offa Community Study Centre ",
    "Ogori Community Study Centre",
    "Oka-Akoko Community Study Centre",
    "Okeho Community Study Centre",
    "Onicha-Ugbo Study Centre",
    "Opi Community Study Centre Nsuka",
    "Osogbo Study Centre",
    "Otan-Ayegbaju Community Study Centre",
    "Otukpo Study Centre ",
    "Owerri Study Centre ",
    "Owhrode Community Study Centre",
    "Patani Community Study Centre",
    "Portharcourt Study Centre",
    "Rimi Gado Study Centre",
    "Sapele Community Study Centre",
    "Shanono Study Centre",
    "Sokoto Study Centre ",
    "Special Study Centre Nigerian Correctional Service National Headquarters Abuja",
    "Surulere Study Centre",
    "Surulere Study Centre Lagos",
    "Tofa Study Centre",
    "Tsanyawa Study Centre",
    "Ugbokolo Study Centre ",
    "Umuahia Correctional Service Study Centre",
    "Umudike Study Centre ",
    "Uromi Community Study Centre",
    "Uyo Study Centre",
    "Viite Special Study Centre Abuja",
    "Wukari Study Centre ",
    "Wuse II Study Centre",
    "Wuse II Study Centre Abuja",
    "Yenagoa Study Centre ",
    "Yola Study Centre "
  ];