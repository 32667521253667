export const updateProfileData = (item) => {
  return {
    type: 'UPDATE_PROFILE_DATA',
    payload: item,
  };
};

export const updateSelectedGroup = (item) => {
  return {
    type: 'UPDATE_SELECTED_GROUP',
    payload: item,
  };
};