import React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <FooterWrapper>
            <p>&copy; <Link style={{color: '#1D8A50'}} to={'https://intradot.com.ng'}>Powered by Intradot</Link></p>
        </FooterWrapper>
    )
}

export default Footer;

const FooterWrapper = styled('div')(() => ({
    position: 'relative',
    width: '100%',
    height: '50px',
    borderTop: '1px solid #E1E1E1',
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    textAlign: 'center',
}));