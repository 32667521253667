import React, { useState, useEffect } from 'react';
import PostAddIcon from '@mui/icons-material/PostAdd';
import './ChatHistory.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentHistory, updateHistory } from '../../../redux/actions/assistanceAction';
import ChatIcon from '@mui/icons-material/Chat';

const ChatHistory = () => {
    const history = useSelector((store) => store.assistance.history);
    const currentHistory = useSelector((store) => store.assistance.currentHistory);
    const dispatch = useDispatch();
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/ai-assistance/chats`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log(result);
            dispatch(updateHistory(result.data));
        })
        .catch((error) => {
            console.error(error)
        });
    }, []);

    const handleClick = (item) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
        };

        fetch(`${window.backendUrl}/api/v1/ai-assistance/chat/details/${item._id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log(result);
            const chats = result.data;
            dispatch(updateCurrentHistory(chats));
        })
        .catch((error) => {
            console.error(error);
        });
    }

    return (
        <div className="chat-history">
            <div onClick={() => dispatch(updateCurrentHistory(null))} className={`history-message ${!currentHistory?.chatData && 'active'}`}>
                <div><PostAddIcon fontSize='16px' /> New Chat</div>
            </div>
            <h2 style={{textAlign: "left", color: '#1D8A50', fontSize: 20, marginBottom: 0, paddingBottom: 10, paddingTop: 10,}}>History</h2>
            {history.map((item,index) => (
                <div onClick={() => handleClick(item)} key={index} className={`history-message ${currentHistory?.chatData?._id === item._id && 'active'}`}>
                    <span>{item?.title.length > 25 ? <>{item.title.substr(0, 25)}...</> : <>{item.title}</> }</span>
                </div>
            ))}
        </div>
    );
};

export default ChatHistory;
