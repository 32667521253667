import React, { useEffect, useState } from 'react';
import { styled } from "@mui/system";
import { useParams } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

import CustomTable from '../../components/table/CustomTable';
import Button from "../../components/button/Button";
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchData, updateSearchMemory, updateExtraSearchTitles } from '../../redux/actions/analyticsAction';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AnalyticsDetailPage() {
  let { title, type } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [category, setCategory] = useState("email");
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [description, setDescription] = useState("");
  const [buttonTextCreate, setButtonTextCreate] = useState("Submit");
  const [buttonLoadingCreate, setButtonLoadingCreate] = useState(false);
  const searchMemory = useSelector((store) => store.analytics.searchMemory);
  const searchData = useSelector((store) => store.analytics.searchData);
  const extraSearchTitles = useSelector((store) => store.analytics.extraSearchTitles);
  const [total, setTotal] = useState(0);
  
  useEffect(() => {
    dispatch(updateSearchMemory(null));
    dispatch(updateSearchData([]));
  }, []);

  const fetchData = async (page, limit, searchQuery) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
    
    const raw = JSON.stringify({
      "field": type,
      "value": title,
      "page": page,
      "limit": limit,
      "searchText": searchQuery
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
        const response = await fetch(`${window.backendUrl}/api/v1/analytics/get-students`, requestOptions);
        const result = await response.json();
        console.log(result);
        setTotal(result?.data?.total);
        return result?.data;
    } catch (error) {
        console.error(error);
        return {};
    }
  };

  const submitSearch = async (searchColumn, searchQuery) => {
    if(searchMemory !== null){
      if(searchMemory[searchColumn]){
        return searchData;
      } else {
        const newSearchData = searchData.filter(item => item[searchColumn] === searchQuery);
        let searchMemoryTemp = searchMemory;
        searchMemoryTemp[searchColumn] = {
          "title": searchData[0][searchColumn],
          "total": newSearchData.length
        }
        let searchTitlesTemp = extraSearchTitles;
        searchTitlesTemp.push(`${newSearchData[0][searchColumn]} (${newSearchData?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`);
        dispatch(updateSearchMemory(searchMemoryTemp));
        dispatch(updateExtraSearchTitles(searchTitlesTemp))
        dispatch(updateSearchData(newSearchData));
        return newSearchData;
      }
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
      
      const raw = JSON.stringify({
        searchColumn,
        searchQuery,
        field: type,
        value: title
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
          const response = await fetch(`${window.backendUrl}/api/v1/analytics/search-students`, requestOptions);
          const result = await response.json();
          console.log(result);
          if(result.data.length > 0){
            let searchMemory = {};
            searchMemory[searchColumn] = {
              "title": result?.data[0][searchColumn],
              "total": result.data.length
            }
            dispatch(updateSearchMemory(searchMemory));
            console.log(result.data[0]);
            dispatch(updateExtraSearchTitles([`${result.data[0][searchColumn]} (${result?.data?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`]))
            dispatch(updateSearchData(result.data));
          }
          return result.data;
      } catch (error) {
          console.error(error);
          return {};
      }
    }
  }

  const handleSend = async () => {
    if (!category || !announcementTitle || !description) {
      Swal.fire({
        title: "Error!",
        text: "Title and description are required!",
        icon: "error",
        confirmButtonText: "Close",
      });
    } else {
      setButtonLoadingCreate(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

      const raw = JSON.stringify({
        "field": type,
        "value": title,
        "title": announcementTitle,
        "description": description,
        "category": category
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${window.backendUrl}/api/v1/analytics/send-announcement`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setButtonTextCreate("Announcement created successfully!");
        setButtonLoadingCreate(false);
        setCategory("email");
        setAnnouncementTitle("");
        setDescription("");
      })
      .catch((error) => {
        console.error(error);
        setButtonTextCreate("Error, try again!");
        setButtonLoadingCreate(false);
      });
    }
  }

  const fetchDataStaff = async (page, limit, searchQuery) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
    
    const raw = JSON.stringify({
      "field": type,
      "value": title,
      "page": page,
      "limit": limit,
      "searchText": searchQuery
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
        const response = await fetch(`${window.backendUrl}/api/v1/analytics/get-staff`, requestOptions);
        const result = await response.json();
        console.log(result);
        setTotal(result?.data?.total);
        return result?.data;
    } catch (error) {
        console.error(error);
        return {};
    }
  };

  const submitSearchStaff = async (searchColumn, searchQuery) => {
    if(searchMemory !== null){
      if(searchMemory[searchColumn]){
        return searchData;
      } else {
        const newSearchData = searchData.filter(item => item[searchColumn] === searchQuery);
        let searchMemoryTemp = searchMemory;
        searchMemoryTemp[searchColumn] = {
          "title": searchData[0][searchColumn],
          "total": newSearchData.length
        }
        let searchTitlesTemp = extraSearchTitles;
        searchTitlesTemp.push(`${newSearchData[0][searchColumn]} (${newSearchData?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`);
        dispatch(updateSearchMemory(searchMemoryTemp));
        dispatch(updateExtraSearchTitles(searchTitlesTemp))
        dispatch(updateSearchData(newSearchData));
        return newSearchData;
      }
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);
      
      const raw = JSON.stringify({
        searchColumn,
        searchQuery,
        field: type,
        value: title
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
          const response = await fetch(`${window.backendUrl}/api/v1/analytics/search-staff`, requestOptions);
          const result = await response.json();
          console.log(result);
          if(result.data.length > 0){
            let searchMemory = {};
            searchMemory[searchColumn] = {
              "title": result?.data[0][searchColumn],
              "total": result.data.length
            }
            dispatch(updateSearchMemory(searchMemory));
            console.log(result.data[0]);
            dispatch(updateExtraSearchTitles([`${result.data[0][searchColumn]} (${result?.data?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`]))
            dispatch(updateSearchData(result.data));
          }
          return result.data;
      } catch (error) {
          console.error(error);
          return {};
      }
    }
  }

  const handleSendStaff = async () => {
    if (!category || !announcementTitle || !description) {
      Swal.fire({
        title: "Error!",
        text: "Title and description are required!",
        icon: "error",
        confirmButtonText: "Close",
      });
    } else {
      setButtonLoadingCreate(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

      const raw = JSON.stringify({
        "field": type,
        "value": title,
        "title": announcementTitle,
        "description": description,
        "category": category
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${window.backendUrl}/api/v1/analytics/send-announcement-staff`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setButtonTextCreate("Announcement created successfully!");
        setButtonLoadingCreate(false);
        setCategory("email");
        setAnnouncementTitle("");
        setDescription("");
      })
      .catch((error) => {
        console.error(error);
        setButtonTextCreate("Error, try again!");
        setButtonLoadingCreate(false);
      });
    }
  }


  return (
    <PageWrapper>
      <Modal
        open={openModal}
        onClose={() => {
          setButtonTextCreate("Submit");
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ marginBottom: "20px" }}
            >
              Send Announcement
            </Typography>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setButtonTextCreate("Submit");
                setOpenModal(false);
              }}
            >
              <CloseIcon style={{ fontSize: "20px", color: "#1D8A50" }} />
            </div>
          </div>
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="input"
            style={{ border: "1px solid #ccc", padding: 0, paddingLeft: 15, }}
          >
            <option value="email">Email</option>
            <option value="sms">SMS</option>
            <option value="push">Push notification</option>
            <option value="both">Email, SMS and Push notification</option>
          </select>
         
          <input
            className="input"
            style={{ border: "1px solid #ccc" }}
            placeholder="Title"
            value={announcementTitle}
            onChange={(e) => setAnnouncementTitle(e.target.value)}
          />
          <textarea
            className="input"
            style={{
              border: "1px solid #ccc",
              height: "100px",
              borderRadius: "8px",
            }}
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <Button
            text={buttonTextCreate}
            isLoading={buttonLoadingCreate}
            onClick={() => {type === "staff" ? handleSendStaff() : handleSend()}}
          />
        </Box>
      </Modal>
      <div style={{display: "flex", flexDirection: "row", gap: 20,}}>
        <h2 className="page-title">{title} ({total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})</h2>
        <button className='button' onClick={() => setOpenModal(true)}>Send Announcement</button>
      </div>
      {type === "staff" || type === "staff-academics" || type === "staff-qualifications" || type === "staff-seniorities" ?
        <CustomTable 
          columns = {[
              { field: "name", header: "Name", width: 500},
              { field: "email", header: "Email", width: 400 },
              { field: "phone", header: "Phone", width: 400 },
              { field: "user_type", header: "Type", width: 400 },
              { field: "study_center", header: "Study Center", width: 500},
          ]}
          fetchData={fetchDataStaff}
          submitSearch={submitSearchStaff} 
        />
      :
        <CustomTable 
          columns = {[
            { field: "matricnumber", header: "Matric No", width: 500},
            { field: "name", header: "Name", width: 500},
            { field: "email", header: "Email", width: 400 },
            { field: "phone", header: "Phone", width: 400 },
            { field: "studycenter", header: "Study Center", width: 400 },
            { field: "department", header: "Department", width: 400 },
            { field: "courseofstudy", header: "Program", width: 400 },
            { field: "level", header: "Level", width: 400 },
          ]}
          fetchData={fetchData}
          submitSearch={submitSearch} 
        />
      }
    </PageWrapper>
  )
}

const PageWrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  width: '100%',
  padding: '30px',
  boxSizing: 'border-box',
  minHeight: '75vh',
}));