import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";

const ChatLayout = () => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(undefined);
    
    useEffect(() => {
        setUserToken(localStorage.getItem("userToken"));
    }, []);
    
    useEffect(() => {
        if(userToken === null){
            navigate("/auth/login");
        }
    }, [userToken]);

    return (
        <LayoutWrapper>
            <PageWrapper>
                <ContentWrapper>
                    <Outlet />
                </ContentWrapper>
            </PageWrapper>
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'flex-start'
}));

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    width: '100%',
    height: '100vh',
    padding: '0rem 0rem',
}));

const ContentWrapper = styled('div')(() => ({
    backgroundColor: '#F5F8F9',
    padding: '0rem',
}));


export default ChatLayout;