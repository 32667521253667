import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';

import AnnouncementCard from "../../components/cards/AnnouncementCard";
import { useDispatch, useSelector } from "react-redux";
import { changeAnnouncementCount } from "../../redux/actions/countAction";
import MenuItem from "../../components/sidebar/Components/MenuItem";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from "@mui/icons-material/Help";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import Button from "../../components/button/Button";

export default function HelpPage() {
    const [message, setMessage] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
  
  const handleSubmit = () => {
    setButtonLoading(true);
    
    if(!message){
        Swal.fire({
            title: 'Error!',
            text: 'Provide a message for us',
            icon: 'error',
            confirmButtonText: 'Close'
        });
        setButtonLoading(false);
    } else {
        let data = JSON.stringify({
            "message": message
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${window.backendUrl}/api/v1/contact/message`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("userToken")}`
            },
            data: data
        };

        axios.request(config)
        .then((response) => {
            //Alert.alert(response?.data?.title, response?.data?.message);
            Swal.fire({
                title: response?.data?.title,
                text: response?.data?.message,
                icon: 'success',
                confirmButtonText: 'Close'
            });
            setButtonLoading(false);
        })
        .catch((error) => {
            console.log(error);
            Swal.fire({
                title: 'Error',
                text: 'Network error try again!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setButtonLoading(false);
        });
          
        
    }
  }
    return (
        <PageWrapper>
            <h2 className="page-title">Help and Support</h2> 
            <div className="row">
                <div className="col-rs-3"></div>
                <div className="col-rs-6" style={{pAlign: 'center'}}>
                    <p className="heading">Do you need a help?</p>
                    <div className="row">
                        <div className="left">
                            <p className="title">Whatsapp Us</p>
                        </div>
                        <div className="right">
                            <Link to='https://wa.me/2349168040806' target="_blank" style={{textDecoration: 'none'}}>
                                <p className="text" style={{color: '#1D8A50'}}><WhatsAppIcon style={{fontSize: 14}} /> 09168040806</p>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">
                            <p className="title">Call Us</p>
                        </div>
                        <div className="right">
                            <Link to='tel: +2348098909080' target="_blank" style={{textDecoration: 'none'}}>
                                <p className="text" style={{color: '#1D8A50'}}><PhoneIcon style={{fontSize: 14}} /> 08098909080</p>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">
                            <p className="title">Email Us</p>
                        </div>
                        <div className="right">
                            <Link to='mailto:support@intradot.com.ng' target="_blank" style={{textDecoration: 'none'}}>
                                <p className="text" style={{color: '#1D8A50'}}><EmailIcon style={{fontSize: 14}} /> support@intradot.com.ng</p>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <p className="title" style={{marginTop: 20}}>Send Us a Direct Message <p className="itemText">(A response message will be send to your email)</p></p>
                    </div>
                    <p className="title">Message</p>
                    <div>
                        <textarea
                            className="input"
                            placeholder={"Message"}
                            placeholderpColor="gray"
                            style={{height: 100, borderRadius: 4, border: '1px solid #d3d3d3', justifyContent: 'flex-start'}}
                            autoCapitalize="none"
                            keyboardType="default"
                            returnKeyType="next"
                            multiline={true}
                            onChange={(e) => setMessage(e.target.value)} ></textarea>
                    </div>
                    <Button text="Submit" onClick={() => handleSubmit()} isLoading={buttonLoading} />
                </div>
                <div className="col-rs-3"></div>
            </div>
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',
    
    '& .heading': {
        fontSize: 18,
        textAlign: "left",
        fontWeight: "bold",
        paddingTop: 0,
        color: '#333'
    },
    '& .title': {
        fontSize: 14,
        textAlign: "left",
        fontWeight: "bold",
        padding: 0,
        color: '#333',
        marginTop: 0,
        paddingTop: 0
    },
    '& .text': {
        fontSize: 14,
        textAlign: "right",
        padding: 0,
        marginTop: 0,
        paddingTop: 0
    },
    '& .left': {
        width: "50%",
        borderWidth: 0,
        textAlign: 'left',
    },
    '& .right': {
        width: "50%",
        borderWidth: 0,
        justifyContent: "flex-start",
        alignItems: "flex-end",
        textAlign: 'right',
    },
    '& .itemText': {
        fontSize: 10,
        paddingTop: 0
    },
    
}));
