import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/system";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Toolbar,
  AppBar,
  IconButton,
  Badge,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { changeAnnouncementCount } from '../../redux/actions/countAction';
import { updateProfileData } from '../../redux/actions/userAction';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  height: 75px;
`;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    color: #1d8a50 !important;
  }
`;

const AccordionMenuItem = styled("div")`
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
  padding-bottom: 4px;
  padding-left: 12px;
  &:hover {
    background: #f3f5f9;
  }
`;

const Header = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const accordionRef = useRef();
  const announcementCount = useSelector((store) => store.count.announcementCount);
  const dispatch = useDispatch();

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${window.backendUrl}/api/v1/auth/profile`,
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("userToken")}`
      }
    };
    setLoading(true);
    axios.request(config)
    .then((response) => {
      console.log(response?.data?.data)
      dispatch(updateProfileData(response?.data?.data));
      localStorage.setItem("type", response?.data?.data?.user_type);
      setData(response?.data?.data);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${window.backendUrl}/api/v1/announcements`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("userToken")}`
        }
    };

    axios.request(config)
    .then((response) => {
        const announcementsData = response?.data?.data;
        const newAnnouncements = announcementsData?.filter((announcement) => !announcement.is_read);
        dispatch(changeAnnouncementCount(newAnnouncements.length));
    })
    .catch((error) => {

    });
}, []);

  useEffect(() => {
    // Define the outside click handler
    const handleClickOutside = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Attach the handler for click event
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("type");
    navigate("/auth/login");
  };

  return (
    <HeaderWrapper>
      <AppBar position="static" color="">
        <StyledToolbar>
          <StyledIconButton></StyledIconButton>
          <div style={{height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
              <Link to="/announcements">
                <IconButton aria-label="show new notifications" color="inherit" style={{height: '20px'}}>
                  <Badge badgeContent={announcementCount} color="error">
                    <NotificationsActiveIcon style={{color: "#1D8A50"}} />
                  </Badge>
                </IconButton>
              </Link>
              <Link to="/profile" className="link">
                <Typography style={{lineHeight: '20px'}}>{data?.name}</Typography>
              </Link>
            </div>
            {/* <Divider orientation="vertical" flexItem />
            <Grid
              container
              spacing={0}
              position="relative"
              //top={isOpen ? 72 : 8}
              style={{border: '1px solid red', height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
            >
              <Grid item>
                <IconButton aria-label="show new notifications" color="inherit">
                  <Badge badgeContent={3} color="error">
                    <NotificationsActiveIcon style={{color: "#1D8A50"}} />
                  </Badge>
                </IconButton>
              </Grid>
              <Grid item>
                <Typography>Auwal Muhammad</Typography>
                <Accordion
                  ref={accordionRef}
                  onChange={(_, isExpanded) => setIsOpen(isExpanded)}
                  expanded={isOpen}
                  sx={{
                    boxShadow: !isOpen
                      ? "none"
                      : `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {loading ? "" : data?.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AccordionMenuItem
                      onClick={() => navigate("/dashboard/my-profile")}
                    >
                      My Profile
                    </AccordionMenuItem>
                    <AccordionMenuItem onClick={handleLogout}>
                      Sign Out
                    </AccordionMenuItem>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid> */}
          </div>
        </StyledToolbar>
      </AppBar>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled("div")(() => ({
  position: "sticky",
  width: "100%",
  backgroundColor: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "flex-start",
  top: 0,
  zIndex: 99,
}));

export default Header;
