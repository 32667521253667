import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import CustomTable from '../../components/table/CustomTable';
import { updateSearchData, updateSearchMemory, updateExtraSearchTitles } from '../../redux/actions/analyticsAction';

const columnsNoun = [
  { field: "id", headerName: "S/N", width: 70 },
  { field: "pay_date", headerName: "Date", width: 250 },
  { field: "matricnumber", headerName: "Matric Number", width: 150 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "status", headerName: "Status", width: 300 },
];

const headersNoun = [
  { key: "id", label: "S/N" },
  { key: "pay_date", label: "Date" },
  { key: "matricnumber", label: "Matric Number" },
  { key: "name", label: "Name" },
  { key: "amount", label: "Amount" },
  { key: "status", label: "Status" },
];

export default function PaymentsPage() {
  const [value, setValue] = React.useState("1");
  const profile = useSelector((store) => store.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchMemory = useSelector((store) => store.analytics.searchMemory);
  const searchData = useSelector((store) => store.analytics.searchData);
  const extraSearchTitles = useSelector((store) => store.analytics.extraSearchTitles);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(updateSearchMemory(null));
    dispatch(updateSearchData([]));
    dispatch(updateExtraSearchTitles([]));
  }, []);

  useEffect(() => {
    if (profile.user_type !== "superadmin") {
      navigate("/");
    }
  }, [profile]);

  const fetchData = async (page, limit) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

    const raw = JSON.stringify({
      "page": page,
      "limit": limit
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${window.backendUrl}/api/v1/data/payments`, requestOptions);
      const result = await response.json();
      console.log(result);
      setTotal(result?.data?.total);
      return result?.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const submitSearch = async (searchColumn, searchQuery) => {
    if (searchMemory !== null) {
      if (searchMemory[searchColumn]) {
        return searchData;
      } else {
        const newSearchData = searchData.filter(item => item[searchColumn] === searchQuery);
        let searchMemoryTemp = searchMemory;
        searchMemoryTemp[searchColumn] = {
          "title": searchData[0][searchColumn],
          "total": newSearchData.length
        }
        let searchTitlesTemp = extraSearchTitles;
        searchTitlesTemp.push(`${newSearchData[0][searchColumn]} (${newSearchData?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`);
        dispatch(updateSearchMemory(searchMemoryTemp));
        dispatch(updateExtraSearchTitles(searchTitlesTemp))
        dispatch(updateSearchData(newSearchData));
        return newSearchData;
      }
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

      const raw = JSON.stringify({
        searchColumn,
        searchQuery
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      try {
        const response = await fetch(`${window.backendUrl}/api/v1/data/search-payments`, requestOptions);
        const result = await response.json();
        console.log(result);
        if (result.data.length > 0) {
          let searchMemory = {};
          searchMemory[searchColumn] = {
            "title": result?.data[0][searchColumn],
            "total": result.data.length
          }
          dispatch(updateSearchMemory(searchMemory));
          console.log(result.data[0]);
          dispatch(updateExtraSearchTitles([`${result.data[0][searchColumn]} (${result?.data?.length?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`]))
          dispatch(updateSearchData(result.data));
        }
        return result.data;
      } catch (error) {
        console.error(error);
        return {};
      }
    }
  }



  return (
    <PageWrapper>
      <h2 className="page-title">Payments</h2>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(_, value) => setValue(value)}
              aria-label="lab API tabs example"
            >
              <Tab label="NOUN Payment" value="1" />
              <Tab label="Remita Payment" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CustomTable
              columns={[
                { field: "pay_date", header: "Date", width: 500 },
                { field: "matricnumber", header: "Matric_Number", width: 500 },
                { field: "name", header: "Name", width: 500 },
                { field: "status", header: "Status", width: 400 },
              ]}
              fetchData={fetchData}
              submitSearch={submitSearch}
            />
          </TabPanel>
          <TabPanel value="2">

          </TabPanel>
        </TabContext>
      </Box>
    </PageWrapper>
  );
}

const PageWrapper = styled("div")(() => ({
  backgroundColor: "#fff",
  width: "100%",
  padding: "30px",
  boxSizing: "border-box",
  minHeight: "75vh",
}));
