import React from "react";
import { styled } from "@mui/system";
import { Typography, Button, Paper, Modal } from "@mui/material";

const ModalWrapper = styled(Paper)({
  width: "400px",
  backgroundColor: "#ffffff",
  borderRadius: 8,
  padding: "12px 24px",
});

const ModalHeader = styled("div")({
  textAlign: "center",
  marginBottom: "12px",
  fontSize: "1.5rem",
  color: "black",
  fontWeight: "bold",
});

const ModalContent = styled("div")({
  marginBottom: (theme) => theme.spacing(2),
});

const ModalActions = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "10px",
});

const ModalButton = styled(Button)({
  borderRadius: 4,
  padding: (theme) => theme.spacing(1, 3),
  fontWeight: "bold",
  letterSpacing: 0.25,
});

const CancelButton = styled(ModalButton)({
  width: "45%",
  color: "#000000",
  backgroundColor: "#DCF8C6",
  "&:hover": {
    backgroundColor: "#1D8A50",
    color: "#ffff"
  },
  marginRight: (theme) => theme.spacing(1),
});

const DeleteButton = styled(ModalButton)({
  width: "45%",
  backgroundColor: "#D12B1E",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#8B0000",
  },
});

const DeleteMessageModal = ({
  deleteModalData,
  setDeleteModalData,
  handleDeleteMessage,
}) => {
  return (
    <Modal
      open={deleteModalData}
      onClose={() => setDeleteModalData(null)}
      style={{ width: "400px", height: "fit-content", margin: "auto", }}
    >
      <ModalWrapper>
        {" "}
        <ModalHeader variant="h6">Delete Message </ModalHeader>
        <ModalContent>
        {deleteModalData?.node?.messageType ? (
          <Typography variant="body1">
            Are you sure you want to delete this{" "}
            {deleteModalData?.node?.messageType}
          </Typography>
        ) : (
          <Typography variant="body1">
            Are you sure you want to delete{" "}
            <strong style={{ color: "grey", fontStyle: "italic" }}>
              "{deleteModalData?.node?.text?.substring(0, 500)}"
            </strong>
          </Typography>
        )}
        </ModalContent>
        <ModalActions>
          <CancelButton onClick={() => setDeleteModalData(null)}>
            No
          </CancelButton>
          <DeleteButton
            onClick={() => {
              handleDeleteMessage(deleteModalData.firebaseId);
            }}
          >
            Yes, Delete
          </DeleteButton>
        </ModalActions>
      </ModalWrapper>
    </Modal>
  );
};

export default DeleteMessageModal;
