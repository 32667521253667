const initialState = {
    history: [],
    currentHistory: null
};
   
export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_HISTORY': 
        return {
            ...state,
            history: action.payload,
        };

        case 'UPDATE_CURRENT_HISTORY': 
        return {
            ...state,
            currentHistory: action.payload,
        };
        
        default:
            return state;
    }
};