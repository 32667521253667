import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import { Typography, Paper, Modal, IconButton } from "@mui/material";
import {
  Close,
  PlayCircleOutline,
  PauseCircleOutline,
  StopCircleOutlined,
  Send,
} from "@mui/icons-material";
import { ref as dbRef, push } from "firebase/database";
import {
  ref as sRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { storage, database } from "../../firebase";
import { useSelector } from "react-redux";

const ModalWrapper = styled(Paper)({
  width: "400px",
  backgroundColor: "#ffffff",
  borderRadius: 8,
  padding: "12px 24px",
});

const ModalHeader = styled("div")({
  textAlign: "center",
  marginBottom: "12px",
  fontSize: "1.5rem",
  color: "black",
  fontWeight: "bold",
});

const ModalContent = styled("div")({
  marginBottom: (theme) => theme.spacing(2),
});

const ModalActions = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "10px",
});

const VoiceNoteModal = ({
  setIsSending,
  currentPrivate,
  showRecording,
  setShowRecording,
  chatId,
  groupId,
  chatType,
  messages,
}) => {
  const [recording, setRecording] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [hasFinishedRecording, setHasFinishedRecording] = useState(false);
  const [isRecordingPlaying, setIsRecordingPlaying] = useState(false);
  const [isVoiceNotePaused, setIsVoiceNotePaused] = useState(false);
  const [currentSound, setCurrentSound] = useState(null);
  const mediaRecorderRef = useRef(null);
  const profile = useSelector((store) => store.user.profile);

  useEffect(() => {
    startRecording();
    return () => {
      if (recording) {
        recording.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  useEffect(() => {
    let interval;
    if (isRecordingPlaying && !isVoiceNotePaused) {
      interval = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecordingPlaying, isVoiceNotePaused]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.onstart = () => {
        setIsRecording(true);
        console.log("Recording started");
      };

      const chunks = [];
      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        const audioURL = URL.createObjectURL(blob);
        setAudioURL(audioURL);
        console.log(audioURL);
      };

      mediaRecorder.start();
      setRecording(mediaRecorder);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (recording) {
      recording.stop();
    }
    setHasFinishedRecording(true);
  };

  const playRecording = () => {
    if (audioURL) {
      const audio = new Audio(audioURL);
      audio.play();
      audio.addEventListener("ended", () => {
        setIsRecordingPlaying(false);
        setElapsedTime(0);
      });
      setCurrentSound(audio);
      setIsRecordingPlaying(true);
    }
  };

  const pauseVoiceNote = async () => {
    try {
      if (currentSound) {
        await currentSound.pause();
        setIsRecordingPlaying(false);
        setIsVoiceNotePaused(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resumeVoiceNote = async () => {
    try {
      if (currentSound) {
        await currentSound.play();
        setIsVoiceNotePaused(false);
        setIsRecordingPlaying(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelRecording = () => {
    if (recording) {
      recording.stop();
    }
    pauseVoiceNote();
    setRecording(null);
    setAudioURL(null);
    setShowRecording(false);
  };

  const uploadRecording = async (uri) => {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      const storageRef = sRef(
        storage,
        "audio/voice-note/" + currentPrivate + "-" + Date.now()
      );
      await uploadBytesResumable(storageRef, blob, {
        contentType: blob.type || "audio/mpeg",
      });
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (err) {
      console.error(err);
    }
  };

  const handleSendRecording = async () => {
    setShowRecording(false);
    setIsSending(true);
    if (!audioURL) return alert('no recording found');
    try {
      if (currentSound) {
        await currentSound.pause();
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const uploadedAudioUrl = await uploadRecording(audioURL);
      const newMessage = {
        id: messages.length + 1,
        text: "Voice Note",
        senderName: profile?.name,
        senderId: profile?._id,
        sendTime: new Date().toLocaleTimeString(),
        createdAt:
          new Date().toLocaleDateString() +
          " " +
          new Date().toLocaleTimeString(),
        currentPrivate: currentPrivate,
        messageType: "audio",
        fileUrl: uploadedAudioUrl,
      };
      const groupPath =
        chatType === "private"
          ? `chats-private/${currentPrivate}`
          : `chats/${groupId}`;

      // Push the new message to the specified group's messages node.
      push(dbRef(database, `${groupPath}/messages`), newMessage);
    //   const payload = {
    //     recepients: chatType === "private" ? chatId : groupId,
    //     chatType: chatType === "private" ? chatId : null,
    //     description: "voice chat",
    //     title: chatType === "private" ? profile.name : "Group Message",
    //   };
      // sendNotification(payload);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSending(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Modal
      open={showRecording}
      onClose={() => setShowRecording(false)}
      style={{ width: "400px", height: "fit-content", margin: "auto" }}
    >
      <ModalWrapper>
        {" "}
        <ModalHeader variant="h6">Voice Message </ModalHeader>
        <ModalContent>
          <Typography variant="body1">
            {hasFinishedRecording ? formatTime(elapsedTime) : formatTime(timer)}
          </Typography>
          {hasFinishedRecording ? (
            <Typography variant="body1">
              Recording is {isRecordingPlaying ? "Playing" : "Paused"}
            </Typography>
          ) : (
            <Typography variant="body1">Recording is ongoing</Typography>
          )}
        </ModalContent>
        <ModalActions>
          <IconButton onClick={cancelRecording}>
            <Close fontSize="large" style={{ color: "#D12B1E" }} />
          </IconButton>
          <IconButton
            onClick={
              hasFinishedRecording
                ? isRecordingPlaying
                  ? pauseVoiceNote
                  : isVoiceNotePaused
                  ? resumeVoiceNote
                  : playRecording
                : stopRecording
            }
          >
            {hasFinishedRecording ? (
              isRecordingPlaying ? (
                <PauseCircleOutline
                  fontSize="large"
                  style={{ color: "black" }}
                />
              ) : (
                <PlayCircleOutline
                  fontSize="large"
                  style={{ color: "black" }}
                />
              )
            ) : (
              <StopCircleOutlined fontSize="large" style={{ color: "black" }} />
            )}
          </IconButton>
          {audioURL ? (
            <IconButton onClick={handleSendRecording}>
            <Send fontSize="large" style={{ color: "#1D8A50" }} />
          </IconButton>
          ) : null}
        </ModalActions>
      </ModalWrapper>
    </Modal>
  );
};

export default VoiceNoteModal;
