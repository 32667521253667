import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route, HashRouter  } from "react-router-dom";
import AuthLayout from './layout/AuthLayout';
import DashboardLayout from './layout/DashboardLayout';

import LoginPage from './pages/auth/LoginPage';
import DashboardPage from './pages/home/DashboardPage';
import PortalSectionsPage from './pages/resources/PortalSectionsPage';
import AnnouncementsPage from './pages/announcements/AnnoucementsPage';
import AnnouncementDetailPage from './pages/announcements/AnnoucementDetailPage';
import ProfilePage from './pages/more/Profile';
import HelpPage from './pages/more/Help';
import ChatsPage from './pages/chats/ChatsPage';
import ChatLayout from './layout/ChatLayout';
import RegisterPage from './pages/auth/RegisterPage';
import RegisterPasswordPage from './pages/auth/RegisterPasswordPage';
import ForgotPasswordPage from './pages/auth/ForgotPassword';
import ActiveUsersPage from './pages/super-admin/ActiveUsers';
import AllUsersPage from './pages/super-admin/AllUsers';
import PaymentsPage from './pages/super-admin/Payments';
import StudentsOnBoardedPage from './pages/super-admin/StudentsOnBoarded';
import PrivacyPolicyPage from './pages/tnc/PrivacyPolicyPage';
import LoansPage from './pages/super-admin/Loans';
import WelcomeNotePage from './pages/super-admin/WelcomeNote';
import AnalyticsPage from './pages/analytics/AnalyticsPage';
import AnalyticPage from './pages/analytics/AnalyticPage';
import AnalyticsListPage from './pages/analytics/AnalyticsListPage';
import AnalyticsDetailPage from './pages/analytics/AnalyticsDetailPage';
import AIAssistancePage from './pages/ai-assistance/AIAssistancePage';

function App() {
  return (
    <HashRouter>
          <Routes>
            <Route path="/" element={<DashboardLayout />}>
              <Route index path="/" element={<DashboardPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/portal-sections" element={<PortalSectionsPage />} />
              <Route path="/announcements" element={<AnnouncementsPage />} />
              <Route path="/announcement/:id" element={<AnnouncementDetailPage />} />
              <Route path="/welcome-note" element={<WelcomeNotePage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/analytics" element={<AnalyticsPage />} />
              <Route path="/analytic/lists/:type" element={<AnalyticsListPage />} />
              <Route path="/analytic/detail/:title/:type" element={<AnalyticsDetailPage />} />
              <Route path="/analytic/:type" element={<AnalyticPage />} />
              <Route path="/ai-assistant" element={<AIAssistancePage />} />
            </Route>
            <Route path="/chats" element={<ChatLayout />}>
              <Route index path="" element={<ChatsPage />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
              <Route index path="login" element={<LoginPage />} />
              <Route index path="forgot-password" element={<ForgotPasswordPage />} />
              <Route index path="register" element={<RegisterPage />} />
              <Route index path="register-password" element={<RegisterPasswordPage />} />
              <Route path="register-password" element={<RegisterPasswordPage />} />
            </Route>
            <Route path="/page">
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            </Route>
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="announcements" element={<h>Announcements</h>} />
            </Route>
            <Route path="/" element={<DashboardLayout />}>
              <Route path="/all-users" element={<AllUsersPage />} />
              <Route path="/students-onboarded" element={<StudentsOnBoardedPage />} />
              <Route path="/active-users" element={<ActiveUsersPage />} />
              <Route path="/payments" element={<PaymentsPage />} />
              <Route path="/loans" element={<LoansPage />} />
            </Route>
            <Route path="*" element={<h2>404 Not Found</h2>} />
          </Routes>
        </HashRouter>
  );
}

export default App;
