import React, {useState} from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

import NounIcon from "../../assets/images/icon.png";
import GooglePlaystoreIcon from "../../assets/images/googleplaystore.png";
import AppleStoreIcon from "../../assets/images/applestore.png";

import Button from "../../components/button/Button";

export default function ForgotPasswordPage() {
    const [username, setUsername] = useState("");
    //const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if(!username){
            Swal.fire({
                title: 'Error!',
                text: 'Enter your username!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            Swal.fire({
                title: 'Success',
                text: 'Password reset request has been sent',
                icon: 'success',
                confirmButtonText: 'Close'
            });
        }
    }
          

    return (
        <LayoutWrapper>
            <LeftWrapper className="desktop-only left-login-container">
                <div className="row"></div>
                <div className="row" style={{textAlign: 'center', justifyContent: 'center'}}>
                    <img src={NounIcon} alt="Logo" width="50%" />
                </div>
                <div style={{marginBottom: 50, justifyContent: 'center'}}>
                    <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 20, marginTop: 20}}>
                        <Link to={"#"} target="_blank">
                            <img src={GooglePlaystoreIcon} alt="Google Playstore" height={50} />
                        </Link>
                        <Link to={"#"} target="_blank">
                            <img src={AppleStoreIcon} alt="Apple Store" height={50} />
                        </Link>
                    </div>
                    <Link to={"https://intradot.com.ng"} target="_blank" className="textLink">
                        <h2>Powered by Intradot</h2>
                    </Link>
                </div>
            </LeftWrapper>
            <RightWrapper className="right-login-container">
                <FormWrapper className="form-wrapper">
                    <div className="row mobile-only" style={{textAlign: 'center'}}>
                        <img src={NounIcon} alt="Logo" width="25%" />
                    </div>
                    <h1 className="title" style={{marginBottom: 20}}>Forgot Password</h1>
                    <input className="input" type="text" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                    
                    <Button text="Submit" onClick={() => handleSubmit()} isLoading={false} />
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20, marginTop: 20,}}>
                        <Link className="text textLink" to="/auth/login">Back to Login</Link>
                    </div>
                    <div className="row mobile-only" style={{marginTop: 30}}>
                        <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 10, marginTop: 20,}}>
                            <Link to={"#"} target="_blank">
                                <img src={GooglePlaystoreIcon} alt="Google Playstore" height={40} />
                            </Link>
                            <Link to={"#"} target="_blank">
                                <img src={AppleStoreIcon} alt="Apple Store" height={40} />
                            </Link>
                        </div>
                        <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', paddingTop: '10px'}}>
                            <Link to={"https://intradot.com.ng"} target="_blank" className="text textLink">Powered by Intradot</Link>
                        </div>
                    </div>
                </FormWrapper>
            </RightWrapper>
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    '& .textLink': {
        color: '#1D8A50 !important',
        textDecoration: 'none'
    },
    '& .textLink:hover': {
        textDecoration: 'underline'
    },
}));

const LeftWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '50%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .row': {
        minHeight: '100px',
    },
    '& h2': {
        color: '#1D8A50',
        textAlign: 'center',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    }
}));

const RightWrapper = styled('div')(() => ({
    backgroundColor: '#1D8A50',
    width: '50%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

const FormWrapper = styled('div')(() => ({
    borderRadius: '20px',
    backgroundColor: '#EAEAEA',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: '70%',
    height: 'auto',
    minHeight: 400,
    padding: '40px',
    boxSizing: 'border-box',
    '& .title': {
        color: '#034524',
        textAlign: 'center',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        padding: 0,
        margin: 0,
        paddingBottom: '10px'
    },
    '& .text': {
        color: '#000',
        textAlign: 'center',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        margin: 0,
        padding: 0,
    },
    '& .input': {
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '50px',
        border: 0,
        marginBottom: 20,
        padding: '15px',
        boxSizing: 'border-box',
        //'-webkit-transition': '0.5s',
        //transition: '0.5s',
        outline: 'none',
    },
    '& .input:focus': {
        border: '1px solid #1D8A50 !important',
    },
    '& .button': {
        color: '#fff',
        backgroundColor: '#1D8A50',
        textAlign: 'center',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        width: '100%',
        height: '50px',
        borderRadius: '50px',
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        border: 0, 
        cursor: 'pointer',
    },
    '& .button:hover': {
        backgroundColor: '#068344'
    },
}));