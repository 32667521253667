import React from "react";
import { styled } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";

import HeaderExternal from "../../components/header/HeaderExternal";
import { Typography } from "@mui/material";
import Footer from "../../components/footer/FooterComponent";

export default function PrivacyPolicyPage() {
    const navigate = useNavigate();    

    return (
        <LayoutWrapper>
            <div style={{width: '100%', position: 'fixed', top: 0, left: 0, height: '75px', zIndex: 9999,}}>
                <HeaderExternal />
            </div>
            <div className="content">
                <h1 style={{textAlign: 'center'}}>Privacy Policy</h1>
                <p>
                    Thank you for using the NOUN eLink App ("the App") developed by [Intradot Limited]. This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information. By using the App, you agree to the terms outlined in this policy.
                </p>
                <h2>Information We Collect:</h2>
                <h3 className="h3">User-Provided Information:</h3>
                <p>When you register for an account, we may collect personal information such as your name, email address, and student ID.</p>
                <p>If you choose to enable additional features, we may collect additional information related to those features.</p>
                <h3 className="h3">Automatically Collected Information:</h3>
                <p>We may collect information about your device, including but not limited to, device type, operating system, and unique device identifiers.</p>
                <p>Usage data, such as the pages you view, the features you access, and the actions you take within the App, may be collected anonymously.</p>
                
                <h2>How We Use Your Information:</h2>
                
                <h3 className="h3">Personal Information:</h3>
                <p>Your personal information is used to login to you student account, personalize your experience, and communicate with you regarding the your academic affairs on the App.</p>
                <p>We may use your email address to send you important updates, notifications, and information about your academic affairs in the university.</p>
                <h3 className="h3">Usage Information:</h3>
                <p>We analyze aggregated usage data to improve the functionality and user experience of the App.</p>
                <p>Usage data may be used for statistical purposes, research, and to enhance our understanding of user behavior.</p>
                
                <h2>Data Security:</h2>
                
                <p>We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
                <p>Access to personal information is restricted to authorized personnel who need the information to perform specific tasks.</p>
                
                <h2>Third-Party Services:</h2>
                
                <p>he App may integrate with third-party services for certain features. Please be aware that these services have their own privacy policies, and we encourage you to review them.</p>
                
                <h2>Your Choices:</h2>
                
                <p>You can manage your account settings and preferences within the App.</p>
                <p>You have the right to opt-out of promotional communications by following the instructions in the communication.</p>
                <p>You have the right to delete your registered account.</p>
                
                <h2>Changes to this Privacy Policy:</h2>
                
                <p>We reserve the right to update this Privacy Policy to reflect changes in our practices or applicable laws. We will notify you of any material changes through the App or other means.</p>
                
                <h2>Contact Us:</h2>
                
                <p>If you have any questions or concerns about this Privacy Policy or the App, please contact us at <Link to="mailto:info@intradot.com.ng" className="link">info@intradot.com.ng</Link></p>
                
                <p>By using the NOUN eLink mobile app, you agree to the terms outlined in this Privacy Policy.</p>
                                
            </div>
            <div style={{position: 'relative', top: '75px'}}>
                <Footer />
            </div>
            
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: 'auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& .content': {
        position: 'relative',
        top: '75px',
        padding: '30px',
        paddingLeft: '5%',
        paddingRight: '5%',
        boxSizing: 'border-box',
        '& h2': {
            fontSize: '20px',
            lineHeight: '30px',
            marginTop: '50px',
        },
        '& .h3': {
            fontSize: '16px',
            lineHeight: '24px',
        },
        '& p': {
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
}));

