import { styled } from "@mui/system";
import React, { useState } from "react";
import SubMenuIcon from "../../../assets/images/submenuicon.svg";

const MenuItemWrapper = styled("div")(
  ({ selected, collapsed, subitem, alignCenter }) => `
    margin-bottom: 8px;
    margin-left: ${subitem ? "10%" : "0rem"};
    padding: ${collapsed ? "0.75rem" : "0.75rem 0.75rem"};
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    background: ${selected ? "#1D8A50" : ""};
    color: ${selected ? "#FBFBFB" : "#111929"};
    width: ${subitem ? collapsed ? "" : "90%" : collapsed ? "" : "100%"};
    box-sizing: border-box;
    justify-content: ${(collapsed || alignCenter) ? "center" : "space-between"};
    gap: 1.125rem;
    cursor: pointer;
    &:hover {
        background: ${selected ? "#1D8A50" : "#F3F5F9"};
        color: ${selected ? "#FBFBFB" : "#111929"};
    }

    > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

`
);
const IconWrapper = styled("div")`
  height: 20px;
`;
const MenuItemText = styled("span")`
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-top: 2px;
  transition: unset;
`;


const RotatingImg = styled("img")(
  ({ opened }) => `
transform: ${opened ? "rotate(180deg)" : "rotate(0deg)"};
// transform: rotate(180deg)
`
);

function MenuItem({
  Icon,
  label,
  selected,
  collapsed,
  onClick,
  nested, 
  open,
  subitem,
  alignCenter
}) {
    const [opened, setOpened] = useState(false)
    const cusOnClick = () => {
        onClick && onClick();
        setOpened(!opened);
    }
  return (
    <MenuItemWrapper
      selected={selected}
      collapsed={collapsed}
      onClick={cusOnClick}
      subitem={subitem}
      alignCenter={alignCenter}
    >
      <div>
        <IconWrapper>{Icon}</IconWrapper>
        {!collapsed && <MenuItemText>{label}</MenuItemText>}
      </div>
      {nested && !collapsed && <RotatingImg src={SubMenuIcon} alt="" opened={!!open}/>}
    </MenuItemWrapper>
  );
}

export default MenuItem;
