import React, { useState, useEffect } from 'react';
import { styled } from "@mui/system";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import AnalyticsSkeleton from '../../components/skeletons/AnalyticsSkeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateAnalyticsData } from '../../redux/actions/analyticsAction';

const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042', 
  '#A28EFF', '#FF6F91', '#FF9671', '#FFC75F',
  '#F9F871', '#36A2EB', '#4BC0C0', '#9966FF', 
  '#FF9F40', '#FF6384', '#C9CBCF', '#4A90E2',
  '#50E3C2', '#B8E986', '#FF5E3A', '#FF9500'
];
const RADIAN = Math.PI / 180;



export default function AnalyticPage() {
  let { type } = useParams();
  const [loading, setLoading] = useState(true);
  const data = useSelector((store) => store.analytics.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickStudyCenter = (data, index) => {
    navigate(`/analytic/detail/${data.name}/studycenter`);
  };
  const handleClickLevel = (data, index) => {
    navigate(`/analytic/detail/${data.name}/level`);
  };
  const handleClickFaculty = (data, index) => {
    navigate(`/analytic/detail/${data.name}/faculty`);
  };
  const handleClickDepartment = (data, index) => {
    navigate(`/analytic/detail/${data.name}/department`);
  };
  const handleClickCourse = (data, index) => {
    navigate(`/analytic/detail/${data.name}/courseofstudy`);
  };
  const handleClickStaff = (data, index) => {
    navigate(`/analytic/detail/${data.name}/staff`);
  };

  const handleClickAcademics = (data, index) => {
    navigate(`/analytic/detail/${data.name}/staff-academics`);
  };
  const handleClickQualifications = (data, index) => {
    navigate(`/analytic/detail/${data.name}/staff-qualifications`);
  };
  const handleClickSeniorities = (data, index) => {
    navigate(`/analytic/detail/${data.name}/staff-seniorities`);
  };

  const renderCustomizedLabelLevel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.level[index];

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderCustomizedLabelFaculty = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.faculty[index];

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderCustomizedLabelAcademics = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.academics[index];

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderCustomizedLabelQualifications = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.qualifications[index];

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderCustomizedLabelSeniorities = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.seniorities[index];

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  useEffect(() => {
    if(data){
      setLoading(false);
    }
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${window.backendUrl}/api/v1/analytics/get-overview`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.code === 200) {
          dispatch(updateAnalyticsData(result?.data));
          console.log(result?.data);
          //setData(result?.data);
        } else {
          Swal.fire({
            title: result.title,
            text: result.message,
            icon: 'error',
            confirmButtonText: 'Close'
          });
        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Error fetching the data, reload the page to fetch again!",
          icon: 'error',
          confirmButtonText: 'Close'
        });
      });
  }, []);

  return (
    <PageWrapper>
      {
        loading ?
          <AnalyticsSkeleton />
          :
          <Box sx={{ flexGrow: 1 }}>
            {type === "studycenter" &&
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Link to={`/analytic/lists/studyCenters`} className="link">
                    <h2>Analytic - Study Centers ({data?.studyCenters?.length})</h2>
                  </Link>
                  <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                    <BarChart
                      width={5000}
                      height={600} data={data.studyCenters}
                      margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" angle={-90} textAnchor="end" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="total" onClick={handleClickStudyCenter}>
                        {data.studyCenters.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </div>
                </Grid>
              </Grid>
            }
            {type === "level" &&
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <Link to={`/analytic/lists/level`} className="link">
                    <h2>Analytic - Level ({data?.level.length})</h2>
                  </Link>
                  <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                    <PieChart>
                      <Pie
                        data={data.level}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={renderCustomizedLabelLevel}
                        outerRadius={225}
                        fill="#8884d8"
                        dataKey="total"
                        onClick={handleClickLevel}
                      >
                        {data.level.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                  <h2 style={{color: 'transparent'}}>`</h2>
                  <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                    {
                      data.level.map((item, index) => (
                        <button
                          onClick={() => handleClickLevel(item, index)}
                          style={{
                            backgroundColor: COLORS[index % COLORS.length], 
                            color: "black", 
                            border: 0,
                            borderRadius: 10,
                            padding: 10,
                            cursor: "pointer",
                          }}
                          key={index}>
                          {item.name === null ?
                          `Others (${item.total.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                          :
                          `Level-${item.name} (${item.total.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                          }
                        </button>
                      ))
                    }
                  </div>
                </Grid>
              </Grid>
            }
            {type === "faculty" &&
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <Link to={`/analytic/lists/faculty`} className="link">
                    <h3>Faculty ({data?.faculty.length})</h3>
                  </Link>
                  <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                    <PieChart>
                      <Pie
                        data={data.faculty}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={renderCustomizedLabelFaculty}
                        outerRadius={225}
                        fill="#8884d8"
                        dataKey="total"
                        onClick={handleClickFaculty}
                      >
                        {data.faculty.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                  <h2 style={{color: 'transparent'}}>`</h2>
                  <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                    {
                      data.faculty.map((item, index) => (
                        <button
                          onClick={() => handleClickFaculty(item, index)}
                          style={{
                            backgroundColor: COLORS[index % COLORS.length], 
                            color: "black", 
                            border: 0,
                            borderRadius: 10,
                            padding: 10,
                            cursor: "pointer",
                          }}
                          key={index}>
                          {item.name === null ?
                          `Others (${item.total.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                          :
                          `${item.name} (${item.total.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                          }
                        </button>
                      ))
                    }
                  </div>
                </Grid>
              </Grid>
            }
            {type === "department" &&
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Link to={`/analytic/lists/department`} className="link">
                    <h2>Analytic - Departments ({data?.department.length})</h2>
                  </Link>
                  <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                    <BarChart
                      width={1500}
                      height={600} data={data.department}
                      margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" angle={-90} textAnchor="end" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="total" onClick={handleClickDepartment}>
                        {data.department.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </div>
                </Grid>
              </Grid>
            }
            {type === "courseofstudy" &&
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Link to={`/analytic/lists/courseOfStudy`} className="link">
                    <h2>Analytic - Programmes ({data?.courseOfStudy.length})</h2>
                  </Link>
                  <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                    <BarChart
                      width={6500}
                      height={600} data={data.courseOfStudy}
                      margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" angle={-90} textAnchor="end" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="total" onClick={handleClickCourse}>
                        {data.courseOfStudy.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </div>
                </Grid>
              </Grid>
            }
            {type === "staff" &&
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <h2>Staff - Study Centers ({data?.staffStudyCenter.length})</h2>   
                    <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                      <BarChart
                        width={8000}
                        height={600} data={data.staffStudyCenter}
                        margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" angle={-90} textAnchor="end" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" onClick={handleClickStaff}>
                          {data.staffStudyCenter.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={5} sx={{marginTop: '15px'}}>
                  <Grid item xs={12} md={12}>
                    <h2>Staff - Academics ({data?.academics.length})</h2>
                    <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                      <PieChart>
                        <Pie
                          data={data.academics}
                          cx="50%"
                          cy="50%"
                          labelLine={true}
                          label={renderCustomizedLabelAcademics}
                          outerRadius={225}
                          fill="#8884d8"
                          dataKey="total"
                          onClick={handleClickAcademics}
                        >
                          {data.academics.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <h2 style={{color: 'transparent'}}>`</h2>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                      {
                        data.academics.map((item, index) => (
                          <button
                            onClick={() => handleClickAcademics(item, index)}
                            style={{
                              backgroundColor: COLORS[index % COLORS.length], 
                              color: "black", 
                              border: 0,
                              borderRadius: 10,
                              padding: 10,
                              cursor: "pointer",
                            }}
                            key={index}>
                            {item.name === null ?
                            `Others (${item.total.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                            :
                            `${item.name} (${item.total.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                            }
                          </button>
                        ))
                      }
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={5} sx={{marginTop: '15px'}}>
                  <Grid item xs={12} md={12}>
                    <h2>Lecturers - Qualifications ({data?.qualifications.length})</h2>
                    <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                      <PieChart>
                        <Pie
                          data={data.qualifications}
                          cx="50%"
                          cy="50%"
                          labelLine={true}
                          label={renderCustomizedLabelQualifications}
                          outerRadius={225}
                          fill="#8884d8"
                          dataKey="total"
                          onClick={handleClickQualifications}
                        >
                          {data.qualifications.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <h2 style={{color: 'transparent'}}>`</h2>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                      {
                        data.qualifications.map((item, index) => (
                          <button
                            onClick={() => handleClickQualifications(item, index)}
                            style={{
                              backgroundColor: COLORS[index % COLORS.length], 
                              color: "black", 
                              border: 0,
                              borderRadius: 10,
                              padding: 10,
                              cursor: "pointer",
                            }}
                            key={index}>
                            {item.name === null ?
                            `Others (${item.total.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                            :
                            `${item.name} (${item.total.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                            }
                          </button>
                        ))
                      }
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={5} sx={{marginTop: '15px'}}>
                  <Grid item xs={12} md={12}>
                    <h2>Lecturers - Seniorities ({data?.seniorities.length})</h2>
                    <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                      <PieChart>
                        <Pie
                          data={data.seniorities}
                          cx="50%"
                          cy="50%"
                          labelLine={true}
                          label={renderCustomizedLabelSeniorities}
                          outerRadius={225}
                          fill="#8884d8"
                          dataKey="total"
                          onClick={handleClickSeniorities}
                        >
                          {data.seniorities.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <h2 style={{color: 'transparent'}}>`</h2>
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                      {
                        data.seniorities.map((item, index) => (
                          <button
                            onClick={() => handleClickSeniorities(item, index)}
                            style={{
                              backgroundColor: COLORS[index % COLORS.length], 
                              color: "black", 
                              border: 0,
                              borderRadius: 10,
                              padding: 10,
                              cursor: "pointer",
                            }}
                            key={index}>
                            {item.name === null ?
                            `Others (${item.total.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                            :
                            `${item.name} (${item.total.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                            }
                          </button>
                        ))
                      }
                    </div>
                  </Grid>
                </Grid>
              </>
            }
          </Box>
      }
    </PageWrapper>
  )
}

const PageWrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  width: '100%',
  padding: '30px',
  boxSizing: 'border-box',
  minHeight: '75vh',
}));