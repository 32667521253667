import { createStore, combineReducers} from 'redux';
import CountReducer from './reducers/countReducer';
import UserReducer from './reducers/userReducer';
import DataReducer from './reducers/dataReducer';
import AnalyticsReducer from './reducers/analyticsReducer';
import AssistanceReducer from './reducers/assistanceReducer';
 
const rootReducer = combineReducers({
  count: CountReducer,
  user: UserReducer,
  data: DataReducer,
  analytics: AnalyticsReducer,
  assistance: AssistanceReducer
});
 
export const store = createStore(rootReducer);