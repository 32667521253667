import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';

import AnnouncementCard from "../../components/cards/AnnouncementCard";
import { useDispatch, useSelector } from "react-redux";
import { changeAnnouncementCount } from "../../redux/actions/countAction";
import MenuItem from "../../components/sidebar/Components/MenuItem";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from "@mui/icons-material/Help";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function ProfilePage() {
    const divedAnnouncement = useSelector((store) => store.count.divAnnouncement);
    const profile = useSelector((store) => store.user.profile);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleLogout = () => {
        Swal.fire({
          //title: 'Are you sure you want to logout?',
          title: 'Logout',
          text: "Are you sure you want to logout?",
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Yes',
          reverseButtons: true
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            localStorage.removeItem("userToken");
            localStorage.removeItem("type");
            navigate("/auth/login");
          }
        });
    };

    return (
        <PageWrapper>
            <h2 className="page-title">Profile</h2> 
            <div className="row">
                <div className="col-rs-3"></div>
                <div className="col-rs-6" style={{textAlign: 'center'}}>
                    {/* <img 
                        style={{ 
                            height: 200, 
                            width: 200, 
                            marginBottom: 0, 
                            alignSelf: 'center',
                            borderRadius: '50%', 
                        }} 
                        src={`data:image/jpeg;base64,${profile?.profile_picture}`} 
                        resizeMode="contain" 
                        alt='User Image'
                    /> */}
                    <AccountCircleIcon style={{fontSize: 150, color: '#808080'}} />
                    <div
                        style={{
                            backgroundColor: "gray",
                            height: 35,
                            borderRadius: 8,
                            marginTop: '20px',
                            marginBottom: '20px',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <p
                            style={{
                            
                            fontSize: 20,
                            color: "#fff",
                            textAlign: "center",
                            }}>
                            {profile?.name}
                        </p>
                    </div>
                    {profile?.user_type === 'student' && <>
                        <div className="row">
                        <div className="left">
                            <p className="title">Matric Number:</p>
                        </div>
                        <div className="right">
                            <p className="text">{profile?.matric_number}</p>
                        </div>
                        </div>
                        <div className="row">
                        <div className="left">
                            <p className="title">Faculty:</p>
                        </div>
                        <div className="right">
                            <p className="text">{profile?.faculty}</p>
                        </div>
                        </div>
                        <div className="row">
                        <div className="left">
                            <p className="title">Department:</p>
                        </div>
                        <div className="right">
                            <p className="text">{profile?.department}</p>
                        </div>
                        </div>
                        <div className="row">
                        <div className="left">
                            <p className="title">Programme:</p>
                        </div>
                        <div className="right">
                            <p className="text">{profile?.programme}</p>
                        </div>
                        </div>
                        <div className="row">
                        <div className="left">
                            <p className="title">Study Center:</p>
                        </div>
                        <div className="right">
                            <p className="text">{profile?.study_center}</p>
                        </div>
                        </div>
                        </>}
                        <Link to="https://nou.edu.ng/calendar" target="_blank" style={{textDecoration: 'none'}}>
                            <MenuItem
                                Icon={<CalendarMonthIcon />}
                                label="Academic Calendar"
                                //onClick={() => navigate("")}
                                selected={true}
                                alignCenter={true}
                            />
                        </Link>
                        <MenuItem
                            Icon={<HelpIcon />}
                            label="Help and Support"
                            onClick={() => navigate("/help")}
                            selected={true}
                            alignCenter={true}
                        />
                        <MenuItem
                            Icon={<LogoutIcon />}
                            label="Logout"
                            onClick={() => handleLogout()}
                            selected={true}
                            alignCenter={true}
                        />
                </div>
                <div className="col-rs-3"></div>
            </div>
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',
    '& .title': {
        fontSize: 14,
        textAlign: "left",
        fontWeight: "bold",
        paddingTop: 0
    },
    '& .text': {
        fontSize: 14,
        textAlign: "right",
        paddingTop: 0
    },
    '& .left': {
        width: "50%",
        borderWidth: 0,
        textAlign: 'left',
    },
    '& .right': {
        width: "50%",
        borderWidth: 0,
        justifyContent: "flex-start",
        alignItems: "flex-end",
        textAlign: 'right',
    },
}));
