import React, { useEffect, useState, useRef } from 'react';
import { styled } from "@mui/system";
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';

import Button from '../button/Button';
import { updateHomeData, updateResourcesData } from '../../redux/actions/dataAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ResourceCard = (props) => {
    const { _id, name, url, iconUrl, status, isRadio } = props;
    const profile = useSelector((store) => store.user.profile);
    const homeData = useSelector((store) => store.data.homeData);
    const [editName, setEditName] = useState('');
    const [editUrl, setEditUrl] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [switchValue, setSwitchValue] = useState(null);

    const dispatch = useDispatch();

    const handleOpen = () => {setButtonText('Save'); setOpen(true)};
    const handleClose = () => {setButtonText('Save'); setOpen(false)};

    useEffect(() => {
        setEditName(name);
        setEditUrl(url);
        setButtonText('Save');
        setSwitchValue(status === 1);
        console.log(status)
    }, [name, url, status]);

    const handleSave = () => {
        setLoading(true);
        let data = JSON.stringify({
            resourceId: _id,
            resourceName: editName,
            resourceUrl: editUrl,
        });

        let config = {
            method: 'patch',
            url: `${window.backendUrl}/api/v1/key-resources/edit`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("userToken")}`
            },
            data: data
        };

        axios.request(config)
        .then((response) => {
            console.log(response?.data);
            dispatch(updateResourcesData(response?.data?.data));
            dispatch(updateHomeData({...homeData, resourceData: response.data.data}));
            setButtonText('Saved successfully!')
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setButtonText('Error, try again!')
            setLoading(false);
        });
    }

    const handleToggle = (checked) => {
        setSwitchValue(checked);
        console.log(checked);
        let data = JSON.stringify({
            resourceId: _id,
            status: checked
        });

        let config = {
            method: 'patch',
            url: `${window.backendUrl}/api/v1/key-resources/edit-status`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("userToken")}`
            },
            data: data
        };

        axios.request(config)
        .then((response) => {
            //console.log(response?.data);
            let resData = response.data.data;
            const radioIndex = resData.findIndex(item => item.isRadio === true);
            const [radioItem] = resData.splice(radioIndex, 1);
            resData.splice(3, 0, radioItem);
            dispatch(updateResourcesData(resData));
            dispatch(updateHomeData({...homeData, resourceData: resData}));
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const handleClick = () => {
        if(isRadio){
            setIsPlaying(prevState => !prevState);
        } else {
            window.open(
                url,
                '_blank'
            );
        }
    }

    useEffect(() => {
        if (audioRef.current) {
          if (isPlaying) {
            audioRef.current.play().catch(error => {
              console.log('Audio playback failed', error);
              setIsPlaying(false);
            });
          } else {
            audioRef.current.pause();
          }
        }
    }, [isPlaying]);

    return(
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: '20px'}}>
                            Edit Portal Section
                        </Typography>
                        <div style={{cursor: 'pointer'}} onClick={handleClose}>
                            <CloseIcon style={{fontSize: '20px', color: '#1D8A50'}} />
                        </div>
                    </div>
                    <input
                        className='input'
                        style={{border: '1px solid #ccc'}}
                        placeholder='Section name'
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                    />
                    <input
                        className='input'
                        style={{border: '1px solid #ccc'}}
                        placeholder='Section url'
                        value={editUrl}
                        onChange={(e) => setEditUrl(e.target.value)}
                    />
                    <Button text={buttonText} isLoading={loading} onClick={() => handleSave()} />
                </Box>
            </Modal>
            {isRadio && <audio ref={audioRef} src={url} loop />}
            <CardWrapper onClick={() => handleClick()}>
                {/* <Link to={url} target='_blank' style={{textDecoration: 'none'}}> */}
                <img 
                    style={{ 
                        height: 60, 
                        width: 60, 
                        marginBottom: 0, 
                        alignSelf: 'center' 
                    }} 
                    src={isRadio ? iconUrl : `data:image/jpeg;base64,${iconUrl}`} 
                    resizeMode="contain" 
                    alt='Icon'
                />
                <div className="gridLabel">{name} {isRadio && <>{isPlaying ? <PauseCircleIcon color='#ffffff' /> : <PlayCircleIcon color='#ffffff' />}</>}</div>
                {/* </Link> */}
            </CardWrapper>
            {(profile?.user_type === "admin" || profile?.user_type === "superadmin") &&
                <div 
                    style={{
                        border: '0px solid red', 
                        height: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                    <Switch 
                        {...label}  
                        style={{color: '#1D8A50',}} 
                        //value={switchValue}
                        checked={switchValue}
                        onChange={(e) => handleToggle(e.target.checked)}
                    />
                    <button 
                        onClick={handleOpen}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            color: '#1D8A50',
                            backgroundColor: 'transparent',
                            border: '0px solid #1D8A50',
                            cursor: 'pointer',
                            borderRadius: '4px',
                            fontSize: '12px',
                            marginTop: '10px',
                            paddingBottom: '10px',
                            height: 'auto',
                            boxSizing: 'border-box'
                        }}
                        >
                        <EditIcon style={{color: '#1D8A50', fontSize: '14px'}} /> Edit
                    </button>
                </div>
            }
        </>
    );
};

const CardWrapper = styled('div')(() => ({
    backgroundColor: '#1D8A50',
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 20,
    boxSizing: 'border-box',
    height: 140,
    borderRadius: 8,
    cursor: 'pointer',

    '& .gridLabel': {
        color: '#fff',
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignContents: 'center',
        gap: 5,
        textAlign: 'center',
    },
}));

// const styles = StyleSheet.create({
//     container: {
//         backgroundColor: '#fff',
//         flex: 1, 
//         flexDirection: 'column', 
//         paddingTop: 20,
//     },
//     resourcesWrapper: {
//         flex: 1, 
//         flexDirection: 'row', 
//         flexWrap: 'wrap', 
//         alignItems: 'flex-start',
//         padding: 10,
//     },
//     title: {
//         fontWeight: '500',
//         fontSize: 18,
//         color: '#333333',
//         marginHorizontal: 20,
//       },
//     text: {
//         fontSize: 14,
//         textAlign: 'center'
//     },
//     button: {
//         backgroundColor: 'lightgreen',
//         marginTop: 10,
//         padding: 5,
//         width: '90%',
//         left: '5%'
//     },
//     gridContainer: {
//         padding: 0,
//     },
//     gridItem: {
//         width: width/2 - 30,
//         //aspectRatio: 1.5, // Make the boxes square
//         margin: 10, // Add some margin for spacing between items
//         borderRadius: 8,
//         backgroundColor: '#1D8A50',
//         justifyContent: 'flex-start',
//         gap: 10,
//         display: 'flex',
//         flexDirection: 'column',
//         padding: 10,
//         height: 120
//     },
//     gridLabel: {
        
//     },
// });

export default ResourceCard;