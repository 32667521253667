import React from 'react';
import { styled } from "@mui/system";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTable from '../../components/table/CustomTable';
import Swal from 'sweetalert2';

export default function AnalyticsListPage() {
  const data = useSelector((store) => store.analytics.data);
  const navigate = useNavigate();
  let { type } = useParams();
  if (!data || (type !== "level" && type !== "department" && type !== "faculty" && type !== "courseOfStudy" && type !== "studyCenters")) {
    navigate("/analytics");
  }
  
  const fetchData = async () => {
    return {
      "students": data[type],
      "total": data[type].length,
      "isComplete": true
    };
  }

  const submitSearch = async () => {
    
  }

  return (
    <PageWrapper>
      <h2 className="page-title">
        List of 
        {type === "level" && " Levels"}
        {type === "department" && " Departments"}
        {type === "faculty" && " Faculties"}
        {type === "courseOfStudy" && " Course of Study"}
        {type === "studyCenters" && " Study Centers"}
      </h2>
      <CustomTable 
        columns = {[
          { field: "name", header: "Name", width: 500},
          { field: "total", header: "Students Total", width: 400 }
        ]}
        fetchData={fetchData}
        submitSearch={submitSearch} 
      />
    </PageWrapper>
  )
}

const PageWrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  width: '100%',
  padding: '30px',
  boxSizing: 'border-box',
  minHeight: '75vh',
}));