import React, {useState} from "react";
import { styled } from "@mui/system";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

import NounIcon from "../../assets/images/icon.png";
import GooglePlaystoreIcon from "../../assets/images/googleplaystore.png";
import AppleStoreIcon from "../../assets/images/applestore.png";

import Button from "../../components/button/Button";

export default function RegisterPasswordPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData } = location.state;
    const [verificationCode, setVerificationCode] = useState('student');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if(!verificationCode || !password || !confirmPassword){
            Swal.fire({
                title: 'Error!',
                text: 'Verification code, password and confirm password are required!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            if(password !== confirmPassword){
                Swal.fire({
                    title: 'Error!',
                    text: 'Password and confirm password are not the same!',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            } else {
                setLoading(true);
                fetch(`${window.backendUrl}/api/v1/auth/register-password`, {
                    method: 'POST',
                    body: JSON.stringify({
                        username: userData?.username,
                        verificationCode: verificationCode,
                        password: password,
                        confirmPassword: confirmPassword
                    }),
                    headers: {
                        'Content-type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(async json => {
                    console.log(json);
                    setLoading(false);
                    if(json.code === 200){
                        const successMessage = json?.message;
                        const data = {
                            username: userData?.username,
                            password: password
                        };
                        fetch(`${window.backendUrl}/api/v1/auth/login`, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json'
                            },
                            body: JSON.stringify(data),
                        })
                        .then(response => response.json())
                        .then(json => {
                            console.log(json);
                            if(json.code === 200){
                                localStorage.setItem("userToken", json.data.token);
                                navigate("/dashboard");
                            } else {
                                Swal.fire({
                                    title: "Success",
                                    text: successMessage,
                                    icon: 'success',
                                    confirmButtonText: 'Close'
                                });
                            }
                            setLoading(false);
                        }).catch(error => {
                            console.log(error);
                            Swal.fire({
                                title: 'Error!',
                                text: 'Invalid login credentials',
                                icon: 'error',
                                confirmButtonText: 'Close'
                            });
                            setLoading(false);
                        });
                    } else {
                        Swal.fire({
                            title: json.title,
                            text: json.message,
                            icon: 'error',
                            confirmButtonText: 'Close'
                        });
                    }
                });
            }
        }
    }
          

    return (
        <LayoutWrapper>
            <LeftWrapper className="desktop-only left-login-container">
                <div className="row"></div>
                <div className="row" style={{textAlign: 'center', justifyContent: 'center'}}>
                    <img src={NounIcon} alt="Logo" width="50%" />
                </div>
                <div style={{marginBottom: 50, justifyContent: 'center'}}>
                    <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 20, marginTop: 20}}>
                        <Link to={"#"} target="_blank">
                            <img src={GooglePlaystoreIcon} alt="Google Playstore" height={50} />
                        </Link>
                        <Link to={"#"} target="_blank">
                            <img src={AppleStoreIcon} alt="Apple Store" height={50} />
                        </Link>
                    </div>
                    <Link to={"https://intradot.com.ng"} target="_blank" className="textLink">
                        <h2>Powered by Intradot</h2>
                    </Link>
                </div>
            </LeftWrapper>
            <RightWrapper className="right-login-container">
                <FormWrapper className="form-wrapper">
                    <div className="row mobile-only" style={{textAlign: 'center'}}>
                        <img src={NounIcon} alt="Logo" width="25%" />
                    </div>
                    <p style={{padding: 0, margin: 0, color: '#1D8A50', textAlign: 'center'}}>Hello! 👋 {userData?.name}</p>
                    <h1 className="title" style={{marginBottom: 20, marginTop: 10}}>Create Your Password</h1>
                    <input className="input" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                    <input className="input" style={{marginBottom: 5}} type="password" placeholder="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} />
                    <p style={{fontSize: 12, padding: 0, margin: 0, paddingLeft: 5}}>Enter the verification code received via email or SMS</p>
                    <p style={{fontSize: 12, color: 'red', padding: 0, margin: 0, marginBottom: 10, paddingLeft: 5}}>({userData?.email} | {userData?.phone})</p>
                    <input className="input" type="text" placeholder="Verification Code" onChange={e => setVerificationCode(e.target.value)} />
                    <Button text="Submit" onClick={() => handleSubmit()} isLoading={loading} />
                    <div className="row mobile-only" style={{marginTop: 30}}>
                        <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 10, marginTop: 20,}}>
                            <Link to={"#"} target="_blank">
                                <img src={GooglePlaystoreIcon} alt="Google Playstore" height={40} />
                            </Link>
                            <Link to={"#"} target="_blank">
                                <img src={AppleStoreIcon} alt="Apple Store" height={40} />
                            </Link>
                        </div>
                        <div style={{justifyContent: 'center', display: 'flex', flexDirection: 'row', paddingTop: '10px'}}>
                            <Link to={"https://intradot.com.ng"} target="_blank" className="text textLink">Powered by Intradot</Link>
                        </div>
                    </div>
                </FormWrapper>
            </RightWrapper>
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    '& .textLink': {
        color: '#1D8A50 !important',
        textDecoration: 'none'
    },
    '& .textLink:hover': {
        textDecoration: 'underline'
    },
}));

const LeftWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '50%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .row': {
        minHeight: '100px',
    },
    '& h2': {
        color: '#1D8A50',
        textAlign: 'center',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    }
}));

const RightWrapper = styled('div')(() => ({
    backgroundColor: '#1D8A50',
    width: '50%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

const FormWrapper = styled('div')(() => ({
    borderRadius: '20px',
    backgroundColor: '#EAEAEA',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    width: '70%',
    height: 'auto',
    minHeight: 400,
    padding: '40px',
    boxSizing: 'border-box',
    '& .title': {
        color: '#034524',
        textAlign: 'center',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        padding: 0,
        margin: 0,
        paddingBottom: '10px'
    },
    '& .text': {
        color: '#000',
        textAlign: 'center',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        margin: 0,
        padding: 0,
    },
    '& .input': {
        borderRadius: '50px',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '50px',
        border: 0,
        marginBottom: 20,
        padding: '15px',
        boxSizing: 'border-box',
        //'-webkit-transition': '0.5s',
        //transition: '0.5s',
        outline: 'none',
    },
    '& .input:focus': {
        border: '1px solid #1D8A50 !important',
    },
    '& .button': {
        color: '#fff',
        backgroundColor: '#1D8A50',
        textAlign: 'center',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        width: '100%',
        height: '50px',
        borderRadius: '50px',
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        border: 0, 
        cursor: 'pointer',
    },
    '& .button:hover': {
        backgroundColor: '#068344'
    },
}));