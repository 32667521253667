const initialState = {
    data: null,
    searchMemory: null,
    searchData: [],
    extraSearchTitles: []
};
   
export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_ANALYTICS_DATA': 
        return {
            ...state,
            data: action.payload,
        };
        case 'UPDATE_SEARCH_MEMORY': 
        return {
            ...state,
            searchMemory: action.payload,
        };
        case 'UPDATE_SEARCH_DATA': 
        return {
            ...state,
            searchData: action.payload,
        };
        case 'UPDATE_EXTRA_SEARCH_TITLES': 
        return {
            ...state,
            extraSearchTitles: action.payload,
        };
        default:
            return state;
    }
};