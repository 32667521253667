import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { CSVLink } from "react-csv";
import Button from "../../components/button/Button";
import PaginationButtons from "../../components/button/PaginationButtons";
import AllUserTab2Page from "./AllUsersTab2";
import AllUserTab4Page from "./AllUsersTab4";
import AllUserTab3Page from "./AllUsersTab3";
import AllUserTab1Page from "./AllUsersTab1";

export default function AllUsersPage() {
    const profile = useSelector((store) => store.user.profile);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <PageWrapper>
            <h2 className="page-title">Users</h2>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#1D8A50',
                            }
                        }}>
                            <Tab label="All Students" value="1" sx={{
                                '&.Mui-selected': {
                                    color: '#1D8A50',
                                    backgroundColor: '#e0f7fa' 
                                },
                                '&:hover': {
                                    backgroundColor: '#b2ebf2',
                                }
                            }} />
                            <Tab label="Students (Onboarded)" value="2" sx={{
                                '&.Mui-selected': {
                                    color: '#1D8A50',
                                    backgroundColor: '#e0f7fa' 
                                },
                                '&:hover': {
                                    backgroundColor: '#b2ebf2',
                                }
                            }} />
                            <Tab label="Staff" value="3" sx={{
                                '&.Mui-selected': {
                                    color: '#1D8A50',
                                    backgroundColor: '#e0f7fa' 
                                },
                                '&:hover': {
                                    backgroundColor: '#b2ebf2',
                                }
                            }} />
                            <Tab label="Admin" value="4" sx={{
                                '&.Mui-selected': {
                                    color: '#1D8A50',
                                    backgroundColor: '#e0f7fa' 
                                },
                                '&:hover': {
                                    backgroundColor: '#b2ebf2',
                                }
                            }} />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <AllUserTab1Page />
                    </TabPanel>
                    <TabPanel value="2">
                        <AllUserTab2Page />
                    </TabPanel>
                    <TabPanel value="3">
                        <AllUserTab3Page />
                    </TabPanel>
                    <TabPanel value="4">
                        <AllUserTab4Page />
                    </TabPanel>
                </TabContext>
            </Box>
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',
}));
