export const updateAnalyticsData = (item) => {
  return {
    type: 'UPDATE_ANALYTICS_DATA',
    payload: item,
  };
};
export const updateSearchMemory = (item) => {
  return {
    type: 'UPDATE_SEARCH_MEMORY',
    payload: item,
  };
};
export const updateSearchData = (item) => {
  return {
    type: 'UPDATE_SEARCH_DATA',
    payload: item,
  };
};
export const updateExtraSearchTitles = (item) => {
  return {
    type: 'UPDATE_EXTRA_SEARCH_TITLES',
    payload: item,
  };
};