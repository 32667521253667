import React from 'react';
import { styled } from "@mui/system";
import { Link } from 'react-router-dom';

const AnnouncementCard = (props) => {
    const {data, height, toLink, isBig} = props;
    
    return(
        <Link to={toLink} style={{textDecoration: 'none'}}>
            <CardWrapper style={isBig ? {height: height,} : {height: height,}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p className="announcementTitle">{data?.title}</p>
                </div>
                {isBig === true ?
                    <p className="content">{data?.description}</p>
                    :
                    <>
                        {data?.description?.length > 200 ? 
                            <p className="content">{data?.description.substr(0, 200)}...</p> 
                            : 
                            <p className="content">{data?.description}</p>
                        }
                    </>
                }
                <p className='timestamp' style={{paddingTop: 10, textAlign: 'right'}}>{data?.date?.substr(0, 10)} {data?.date?.substr(11, 5)}</p>
            </CardWrapper>
        </Link>
    )
};

const CardWrapper = styled('div')(() => ({
    backgroundColor: '#ecf5f4',
    borderRadius: 16,
    padding: 16,
    marginBottom: 16,
    boxSizing: 'border-box',

    '& .announcementTitle': {
        color: 'darkgreen',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    '& .timestamp': {
        color: 'darkgreen',
        fontSize: 14,
        marginBottom: 8,
    },
    '& .content': {
        color: 'darkgreen',
        fontSize: 16,
        fontWeight: '700'
    },
}));

export default AnnouncementCard