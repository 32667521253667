export const updateHomeData = (item) => {
  return {
    type: 'UPDATE_HOME_DATA',
    payload: item,
  };
};

export const updateResourcesData = (item) => {
  return {
    type: 'UPDATE_RESOURCES_DATA',
    payload: item,
  };
};

export const updateAnnouncementsData = (item) => {
  return {
    type: 'UPDATE_ANNOUNCEMENTS_DATA',
    payload: item,
  };
};
