import React from "react";
import { styled } from "@mui/system";
import {
  Toolbar,
  AppBar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import LogoShort from "../../assets/images/logo-short.png";

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  height: 75px;
  width: 100%;
  border: 0px solid red;
  box-sizing: border-box;

`;

const HeaderExternal = () => {

  return (
    <HeaderWrapper>
      <AppBar position="static" color="">
        <StyledToolbar>
          <div style={{height: '75px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <Link to="/" className="link">
              <img src={LogoShort} alt="logo" height="65px" />
            </Link>
            <Link to="/" className="link">
              <div style={{height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Typography style={{lineHeight: '20px', fontWeight: 'bold', fontSize: '20px', paddingLeft: '20px', color: '#1D8A50'}}>National Open University of Nigeria</Typography>
              </div>
            </Link>
          </div>
          <div style={{height: '75px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Link to="/auth/login" className="link">
              <Typography style={{lineHeight: '20px', fontWeight: 500}}>Login</Typography>
            </Link>
          </div>
        </StyledToolbar>
      </AppBar>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled("div")(() => ({
  position: "sticky",
  width: "100%",
  backgroundColor: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "flex-start",
  top: 0,
  zIndex: 99,
}));

export default HeaderExternal;
