import React, { useState, useEffect } from 'react';
import { styled } from "@mui/system";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import AnalyticsSkeleton from '../../components/skeletons/AnalyticsSkeleton';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateAnalyticsData } from '../../redux/actions/analyticsAction';

const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042', 
  '#A28EFF', '#FF6F91', '#FF9671', '#FFC75F',
  '#F9F871', '#36A2EB', '#4BC0C0', '#9966FF', 
  '#FF9F40', '#FF6384', '#C9CBCF', '#4A90E2',
  '#50E3C2', '#B8E986', '#FF5E3A', '#FF9500'
];
const RADIAN = Math.PI / 180;

export default function AnalyticsPage() {
  const [loading, setLoading] = useState(true);
  const data = useSelector((store) => store.analytics.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickStudyCenter = (data, index) => {
    navigate(`/analytic/detail/${data.name}/studycenter`);
  };
  const handleClickLevel = (data, index) => {
    navigate(`/analytic/detail/${data.name}/level`);
  };
  const handleClickFaculty = (data, index) => {
    navigate(`/analytic/detail/${data.name}/faculty`);
  };
  const handleClickDepartment = (data, index) => {
    navigate(`/analytic/detail/${data.name}/department`);
  };
  const handleClickCourse = (data, index) => {
    navigate(`/analytic/detail/${data.name}/courseofstudy`);
  };

  const renderCustomizedLabelLevel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.level[index];
    

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const renderCustomizedLabelFaculty = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    //const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const currentItem = data.faculty[index];

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${currentItem?.name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  useEffect(() => {
    if(data){
      setLoading(false);
    }
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${window.backendUrl}/api/v1/analytics/get-overview`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.code === 200) {
          dispatch(updateAnalyticsData(result?.data));
          //setData(result?.data);
        } else {
          Swal.fire({
            title: result.title,
            text: result.message,
            icon: 'error',
            confirmButtonText: 'Close'
          });
        }
        setLoading(false);
      }).catch((error) => {
        console.error(error);
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Error fetching the data, reload the page to fetch again!",
          icon: 'error',
          confirmButtonText: 'Close'
        });
      });
  }, []);

  return (
    <PageWrapper>
      <h2 className="page-title">Analytics</h2>
      {
        loading ?
          <AnalyticsSkeleton />
          :
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Link to={`/analytic/lists/studyCenters`} className="link">
                  <h3>Study Centers ({data?.studyCenters?.length})</h3>
                </Link>
                <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                  <BarChart
                    width={5000}
                    height={600} data={data.studyCenters}
                    margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-90} textAnchor="end" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" onClick={handleClickStudyCenter}>
                      {data.studyCenters.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Bar>
                  </BarChart>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                <Link to={`/analytic/lists/level`} className="link">
                  <h3>Level ({data?.level.length})</h3>
                </Link>
                <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                  <PieChart>
                    <Pie
                      data={data.level}
                      cx="50%"
                      cy="50%"
                      labelLine={true}
                      label={renderCustomizedLabelLevel}
                      outerRadius={225}
                      fill="#8884d8"
                      dataKey="total"
                      onClick={handleClickLevel}
                    >
                      {data.level.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                  {
                    data.level.map((item, index) => (
                      <button
                        onClick={() => handleClickLevel(item, index)}
                        style={{
                          backgroundColor: COLORS[index % COLORS.length], 
                          color: "black", 
                          border: 0,
                          borderRadius: 10,
                          padding: 10,
                          cursor: "pointer",
                        }}
                        key={index}>
                        {item.name === null ?
                        `Others (${item.total.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                        :
                        `Level-${item.name} (${item.total.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                        }
                      </button>
                    ))
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <Link to={`/analytic/lists/faculty`} className="link">
                  <h3>Faculty ({data?.faculty.length})</h3>
                </Link>
                <ResponsiveContainer width="100%" height={500} style={{ marginTop: 20 }}>
                  <PieChart>
                    <Pie
                      data={data.faculty}
                      cx="50%"
                      cy="50%"
                      labelLine={true}
                      label={renderCustomizedLabelFaculty}
                      outerRadius={225}
                      fill="#8884d8"
                      dataKey="total"
                      onClick={handleClickFaculty}
                      style={{zIndex: 999}}
                    >
                      {data.faculty.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10, marginTop: 20,}}>
                  {
                    data.faculty.map((item, index) => (
                      <button
                        onClick={() => handleClickFaculty(item, index)}
                        style={{
                          backgroundColor: COLORS[index % COLORS.length], 
                          color: "black", 
                          border: 0,
                          borderRadius: 10,
                          padding: 10,
                          cursor: "pointer",
                        }}
                        key={index}>
                        {item.name === null ?
                        `Others (${item.total.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                        :
                        `${item.name} (${item.total.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
                        }
                      </button>
                    ))
                  }
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={12}>
                <Link to={`/analytic/lists/department`} className="link">
                  <h3>Departments ({data?.department.length})</h3>
                </Link>
                <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                  <BarChart
                    width={1500}
                    height={600} data={data.department}
                    margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-90} textAnchor="end" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" onClick={handleClickDepartment}>
                      {data.department.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Bar>
                  </BarChart>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12} md={12}>
                <Link to={`/analytic/lists/courseOfStudy`} className="link">
                  <h3>Programmes ({data?.courseOfStudy.length})</h3>
                </Link>
                <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                  <BarChart
                    width={6500}
                    height={600} data={data.courseOfStudy}
                    margin={{ top: 20, right: 20, left: 0, bottom: 200 }}  // Increased left and bottom margins
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" angle={-90} textAnchor="end" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" onClick={handleClickCourse}>
                      {data.courseOfStudy.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Bar>
                  </BarChart>
                </div>
              </Grid>
            </Grid>
          </Box>
      }
    </PageWrapper>
  )
}

const PageWrapper = styled('div')(() => ({
  backgroundColor: '#fff',
  width: '100%',
  padding: '30px',
  boxSizing: 'border-box',
  minHeight: '75vh',
}));