export const updateHistory = (item) => {
  return {
    type: 'UPDATE_HISTORY',
    payload: item,
  };
};

export const updateCurrentHistory = (item) => {
  return {
    type: 'UPDATE_CURRENT_HISTORY',
    payload: item,
  };
};
