import { styled } from "@mui/system";
import { Box } from "@mui/material";
import React from "react";
import MenuItem from "./MenuItem";

const SubMenuItemWrapper = styled(Box)`

`;

function SubMenuItem({
  children,
  onClick,
  collapsed,
  Icon,
  label, open
}) {
  return (
    <div>
      <MenuItem
        Icon={Icon }
        label={label}
        selected={false}
        collapsed={collapsed}
        onClick={onClick}
        nested={true}
        open={open}
      />{open && !collapsed &&
      <SubMenuItemWrapper>{children}</SubMenuItemWrapper>}
    </div>
  );
}

export default SubMenuItem;
